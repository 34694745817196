<template>
  <v-container
    fluid
    :class="$store.state.base.admin.isAdminMode ? 'adminView py-1' : 'studentView py-1'"
  >
    <v-container
      v-if="$store.state.base.admin.isAdminMode"
      appwidth
      class="py-0 px-2"
    >
      <v-autocomplete
        v-model="$store.state.base.admin.administratedOrg"
        :items="$store.state.base.admin.administratableOrgs"
        item-text="name"
        item-value="uid"
        :filter="customFilter"
        :label="$t('base.orgSelector.administratedOrg')"
        @change="setAdministratedOrgChanged"
        class="admin-org-selector"
        :disabled="$store.state.base.admin.loadingOrgs || $store.state.base.admin.lockAdminOrgSelector"
        :loading="$store.state.base.admin.loadingOrgs"
      >
        <template v-slot:item="data">
          <template>
            <v-list-item-content
              v-text="data.item.name"
              class="selector-item"
            ></v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </v-container>
  </v-container>
</template>

<script lang="js">
import { setAdministratedOrg } from '@/baseAdmin/js/baseAdminHelper'; ;

export default {
  name: 'AdminOrgSelector',
  methods: {
    customFilter (item, queryText, itemText) {
      const textOne = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    setAdministratedOrgChanged (orgUid) {
      for (let org of this.$store.state.base.admin.administratableOrgs) {
        if (orgUid === org.uid) {
          setAdministratedOrg(org);
          this.$logger.debug('accAdmin', 'setAdministratedOrg: ', JSON.stringify(org));
        }
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.admin-org-selector {
  max-width: 600px;
}
.selector-item {
  padding: 4px 8px !important;
}
.studentView {
  height: 4px;
}
.adminView {
  height: 58px;
}
</style>
