<template>
  <v-card
    flat
    :class="css"
  >
    <v-card-title class="px-0 pt-0">
      <v-icon
        v-if="icon"
        class="text-h5 vCenter text--primary"
      >
        {{ icon }}
      </v-icon>
      <span class="ml-4 text-h5 vCenter text--primary">
        {{ title }}
      </span>
      <v-spacer />
      <v-btn
        v-if="backButton"
        @click="$emit('back')"
        class="prio2"
      >
        {{ backButtonText }}
      </v-btn>
    </v-card-title>
  </v-card>
</template>

<script lang="js">
export default {
  name: 'Headline',
  props: {
    icon: { type: String, default: '' },
    title: { type: String, required: true },
    backButton: { type: Boolean, default: false },
    backText: { type: String, default: '' },
    css: { type: String, default: 'standardBg' }
  },
  computed: {
    backButtonText () {
      return this.backText.length > 0 ? this.backText : this.$t('base.back');
    }
  }
};
</script>

<style scoped>
</style>
