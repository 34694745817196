import Vue from 'vue';
import vuexI18n from 'vuex-i18n/dist/vuex-i18n.umd.js';
const appConfig = require('@/.generic/appConfig.json');

// let isRunning = [];

export default async function i18nInit (store) {
  Vue.use(
    vuexI18n.plugin,
    store
  );

  // Set the start locale to use
  Vue.i18n.set(store.state.base.curLocale);
  Vue.i18n.fallback(appConfig.locales.fallback);
}
