import Dashboard from '@/base/Dashboard.vue';

export const routes = [
  {
    path: '/',
    component: Dashboard
  },
  {
    path: '/chooseOrg',
    component: () => import(/* webpackChunkName: 'base' */ '@/base/ChooseOrg.vue')
  },
  {
    path: '/inactive',
    component: () => import(/* webpackChunkName: 'base' */ '@/base/UserInactive.vue')
  },
  {
    path: '/goodBye',
    component: () => import(/* webpackChunkName: 'base' */ '@/base/GoodBye.vue'),
    meta: { noAuthRequired: true }
  },
  // {
  //   path: '/debugger',
  //   component: () => import(/* webpackChunkName: 'base' */ '@/base/Debugger.vue'),
  //   meta: { noAuthRequired: true }
  // },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: 'base' */ '@/base/Error.vue'),
    meta: { noAuthRequired: false }
  },
  {
    path: '/dashboard',
    component: Dashboard
  },
  {
    path: '/imprint',
    component: () => import(/* webpackChunkName: 'base' */ '@/base/Imprint.vue'),
    meta: { noAuthRequired: true }
  },
  {
    path: '/privacy',
    component: () => import(/* webpackChunkName: 'base' */ '@/base/Privacy.vue'),
    meta: { noAuthRequired: true }
  },
  {
    path: '*',
    redirect: '/404'
  }
,

  {
    path: '/acc/login',
    component: () => import(/* webpackChunkName: 'acc' */ '@/acc/Login.vue'),
    meta: { noAuthRequired: true }
  },
  {
    path: '/acc/register/:invitationKey',
    props: true,
    component: () => import(/* webpackChunkName: 'acc' */ '@/acc/UserRegistration.vue'),
    meta: { noAuthRequired: true }
  },
  {
    path: '/acc/register/',
    component: () => import(/* webpackChunkName: 'acc' */ '@/acc/UserRegistration.vue')
  },
  {
    path: '/acc/profile',
    component: () => import(/* webpackChunkName: 'acc' */ '@/acc/MyProfile.vue')
  }
,

  {
    path: '/accAdmin',
    component: () => import(/* webpackChunkName: 'accAdmin' */ '@/accAdmin/AccAdmin.vue'),
    children: [
      { path: '', redirect: 'accounts' },
      {
        path: 'accounts',
        component: () => import(/* webpackChunkName: 'accAdmin' */ '@/accAdmin/Organisations.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: 'accAdmin' */ '@/accAdmin/components/OrgTreeTable.vue')
          },
          {
            path: 'create',
            name: 'accAdminAccountsCreate',
            props: true,
            component: () => import(/* webpackChunkName: 'accAdmin' */ '@/accAdmin/components/OrgCreate.vue')
          },
          {
            path: ':targetOrgUid',
            props: true,
            component: () => import(/* webpackChunkName: 'accAdmin' */ '@/accAdmin/components/OrgProfile.vue')
          }
        ]
      },
      {
        path: 'users',
        component: () => import(/* webpackChunkName: 'accAdmin' */ '@/accAdmin/Users.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: 'accAdmin' */ '@/accAdmin/components/UserTable.vue')
          },
          {
            path: 'invite',
            props: true,
            component: () => import(/* webpackChunkName: 'accAdmin' */ '@/accAdmin/components/UserInvite.vue')
          },
          {
            path: ':targetUserUid',
            props: true,
            component: () => import(/* webpackChunkName: 'acc' */ '@/acc/components/UserProfile.vue')
          }
        ]
      },
      {
        path: 'groups',
        component: () => import(/* webpackChunkName: 'accAdmin' */ '@/accAdmin/Groups.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: 'accAdmin' */ '@/accAdmin/components/GroupTable.vue')
          },
          {
            path: 'create',
            name: 'accAdminGroupCreate',
            component: () => import(/* webpackChunkName: 'accAdmin' */ '@/accAdmin/components/GroupDetails.vue')
          },
          {
            path: ':targetGroupId',
            props: true,
            component: () => import(/* webpackChunkName: 'accAdmin' */ '@/accAdmin/components/GroupDetails.vue')
          }
        ]
      }
    ]
  }
,

  {
    path: '/condAdmin',
    component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/CondAdmin.vue'),
    children: [
      { path: '', redirect: 'condition' },
      // {
      //   path: 'test',
      //   component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/ConditionTest.vue')
      // },
      {
        path: 'upload',
        component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/ConditionUpload.vue')
      },
      {
        path: 'dealer',
        component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/Dealer.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/components/DealerTable.vue')
          },
          {
            path: 'create',
            component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/components/DealerDetail.vue')
          },
          {
            path: ':dealerId',
            props: true,
            component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/components/DealerDetail.vue')
          }
        ]
      },
      {
        path: 'provider',
        component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/Provider.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/components/ProviderTable.vue')
          },
          {
            path: 'create',
            component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/components/ProviderDetail.vue')
          },
          {
            path: ':providerUid',
            props: true,
            component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/components/ProviderDetail.vue')
          }
        ]
      },
      {
        path: 'calcType',
        component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/CalcType.vue')
      },
      {
        path: 'condition',
        component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/Condition.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/components/ConditionTable.vue')
          },
          {
            path: ':conditionUid/:conditionVersion',
            props: true,
            component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/components/ConditionDetail.vue')
          },
          {
            path: 'edit',
            component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/components/ConditionEditor.vue')
          }
        ]
      },
      {
        path: 'productType',
        component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/ProductType.vue')
      },
      {
        path: 'residualValues',
        component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/ResidualValues.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/components/ResidualValuesTable.vue')
          },
          {
            path: ':rvTableKey/:rvVersion',
            props: true,
            component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/components/ResidualValuesDetail.vue')
          },
          {
            path: 'edit',
            component: () => import('@/condAdmin/components/ResidualValuesEditor.vue')
          }
        ]
      },
      {
        path: 'product',
        component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/Product.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/components/ProductTable.vue')
          },
          {
            path: 'create',
            component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/components/ProductDetail.vue')
          },
          {
            path: ':productUid',
            props: true,
            component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/components/ProductDetail.vue')
          }
        ]
      },
      {
        path: 'rvGroup',
        component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/RvGroup.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/components/RvGroupTable.vue')
          },

          {
            path: 'edit/:rvGroupTabPosition',
            props: true,
            component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/components/RvGroupEditor.vue')
          },
          {
            path: ':rvGroupUid/:rvGroupVersion/:rvGroupTabPosition',
            props: true,
            component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/components/RvGroupDetail.vue')
          }

        ]
      },
      {
        path: 'additionalProduct',
        component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/AdditionalProducts.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/components/AdditionalProductTable.vue')
          },
          {
            path: 'edit',
            component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/components/AdditionalProductEditor.vue')
          },
          {
            path: ':addProductObjectKey',
            props: true,
            component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/components/AdditionalProductDetail.vue')
          }

        ]
      },
      {
        path: 'partnerOfTheWeek',
        component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/PartnerOfTheWeek.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/components/PartnerOfTheWeekDetail.vue')
          },
          {
            path: 'edit',
            component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/components/PartnerOfTheWeekEditor.vue')
          },
          {
            path: ':partOfTheWeekObjectKey',
            props: true,
            component: () => import(/* webpackChunkName: 'condAdmin' */ '@/condAdmin/components/PartnerOfTheWeekDetail.vue')
          }
        ]
      }
    ]
  }
,

  {
    path: '/calcRepAdmin',
    component: () => import(/* webpackChunkName: 'calcRepAdmin' */ '@/calcRepAdmin/calcRepAdmin.vue'),
    children: [
      { path: '', redirect: 'reporting' },
      {
        path: 'reporting',
        component: () => import(/* webpackChunkName: 'calcRepAdmin' */ '@/calcRepAdmin/calcRepCount.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: 'calcRepAdmin' */ '@/calcRepAdmin/components/calcRepCountTable.vue')
          }
        ]
      }
    ]
  }
];
