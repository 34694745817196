const deepmerge = require('@/base/js/Deepmerge.js');

export const defaultProcessState = (state = 'success', messages = []) => {
  return {
    state: state,
    messages: messages
  };
};

export const mergeProcessData = (data1, data2) => {
  // header doesn't change
  data1.processState = mergeProcessState(data1.processState, data2.processState);
  data1.ioData = deepmerge(data1.ioData, data2.ioData);
  return data1;
};

export const mergeProcessState = (processState1, processState2) => {
  if (!processState1) {
    processState1 = defaultProcessState();
  }
  // decide which state to use
  if (getProcessStateValue(processState1.state) < getProcessStateValue(processState2.state)) {
    processState1.state = processState2.state;
  }
  // merge messages
  for (let i = 0; i < processState2.messages.length; i++) {
    processState1.messages.push(processState2.messages[i]);
  }
  return processState1;
};

export const mergeIoData = (ioData1, ioData2) => {
  return deepmerge(ioData1, ioData2);
};

export const addMessage = (processState, type, text) => {
  if (!processState.state || getProcessStateValue(processState.state) < getProcessStateValue(type)) {
    processState.state = type;
  }
  processState.messages.push({ type: type, message: text });
  return processState;
};

export const newMessage = (type, text) => {
  return { type: type, message: text };
};

export const getProcessStateValue = (state) => {
  switch (state) {
    case 'success': return 0;
    case 'warning': return 1;
    case 'userError': return 2;
    case 'systemError': return 3;
    case 'networkError': return 4;
    default: return 3;
  }
};

export const isProcessStateOK = (data) => {
  if (data.processState.state === 'success' || data.processState.state === 'warning') {
    return true;
  }
  return false;
};
