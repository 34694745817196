export class AdminObject {
  isAdmin = false;
  isAdminMode = false;
  topAdminNav = [];
  administratableOrgs = [];
  administratedOrg = null;
  loadingOrgs = true;
  loadingUsers = true;
  loadingGroups = true;
  administratedOrgObjects = {
    orgList: [],
    userList: [],
    roleList: [],
    groupList: [],
    orgSettings: {},
    baseSettings: {},
    defaultGroup: null
  };
  lockAdminOrgSelector = false;
  themes = null;
}
