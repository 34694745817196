// plugins/vuetifyInit.js
import Vue from 'vue';
import Vuetify from 'vuetify';
import '@mdi/font/css/materialdesignicons.css';

import de from 'vuetify/lib/locale/de';
import gb from 'vuetify/lib/locale/en';
import fr from 'vuetify/lib/locale/fr';

const appConfig = require('@/.generic/appConfig.json');

Vue.use(Vuetify);

export const vuetify = new Vuetify({
  lang: {
    locales: { de, gb, fr },
    current: 'de'
  },
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: appConfig.theme.light
    }
  }
});
