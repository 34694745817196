import Vue from 'vue';
import Vuex from 'vuex';
import { baseStore } from '@/base/baseStore';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    base: baseStore
  },

  strict: false
});
