import { ImageHelper } from '@/base/js/ImageHelper';

export class accHelper {
  static copyUser (source = {}, assetsUrl = null) {
    let user = {
      uid: source.uid != null ? source.uid : null,
      uidType: source.uidType != null ? source.uidType : '',
      salutation: source.salutation != null ? source.salutation.trim() : '',
      title: source.title != null ? source.title.trim() : '',
      firstname: source.firstname != null ? source.firstname.trim() : '',
      surname: source.surname != null ? source.surname.trim() : '',
      username: source.username != null ? source.username.trim() : '',
      email: source.email != null ? source.email.trim() : '',
      emailVerified: source.emailVerified != null ? source.emailVerified : '0',
      mobile: source.mobile != null ? source.mobile.trim() : '',
      mobileVerified: source.mobileVerified != null ? source.mobileVerified : '0',
      invitationState: source.invitationState != null ? source.invitationState : '',
      state: source.state != null ? source.state : 'active',
      avatarBin: source.avatarBin != null ? source.avatarBin : null,
      avatar: source.avatar != null ? source.avatar : ''
    };
    user.fullName = (user.firstname + ' ' + user.surname).trim();
    if (assetsUrl && typeof source.avatar === 'string' && source.avatar.length > 0 && source.avatar !== '-') {
      user.avatar = source.avatar;
      ImageHelper.getImage(assetsUrl + user.avatar)
        .then((avatarBin) => {
          if (avatarBin) {
            user.avatarBin = avatarBin;
          }
        });
    }
    // if (user.salutation.length === 0) user.salutation = ' '; // ??
    return user;
  }

  static copyOrganisation (source = {}) {
    return {
      uid: source.uid != null ? source.uid : null,
      uidType: source.uidType != null ? source.uidType : '',
      shortname: source.shortname != null ? source.shortname : '',
      name: source.name != null ? source.name : '',
      address1: source.address1 != null ? source.address1 : '',
      address2: source.address2 != null ? source.address2 : '',
      zipCode: source.zipCode != null ? source.zipCode : '',
      city: source.city != null ? source.city : '',
      region: source.region != null ? source.region : '',
      country: source.country != null ? source.country : '',
      isActive: source.isActive != null ? source.isActive : true,
      type: source.type != null ? source.type : 0,
      userCount: source.userCount != null ? source.userCount : 0,
      logo: source.logo != null ? source.logo : null
    };
  }
};
