<template>
  <v-toolbar-items>
    <v-btn
      v-if="$store.getters.orgReady"
      id="headerMenuNavigateToHome"
      @click="navigateToModule('base')"
      text
      class="px-4 primaryContrast--text"
      :class="activeModuleUid === 'base' ? 'primary lighten-1' : 'primary'"
    >
      <v-icon>mdi-home</v-icon>
    </v-btn>
    <v-btn
      v-for="(moduleId) in navMenu"
      :key="moduleId"
      :id="'headerMenuNavigateTo' + moduleId"
      @click.native="navigateToModule(moduleId)"
      text
      class="px-4 primaryContrast--text"
      :class="activeModuleUid === moduleId ? 'primary lighten-1' : 'primary'"
    >
      <v-icon
        v-if="getIcon(moduleId)"
        class="small"
      >{{ getIcon(moduleId) }}</v-icon>
      {{ $t("base.moduleList." + moduleId) }}
    </v-btn>
    <v-spacer class="primary" />
    <v-btn
      v-if="$store.state.base.previewTheme"
      @click.native="resetThemePreview"
      class="pr-4 secondary secondaryContrast--text"
    >
      {{ $t("accAdmin.theme.stopPreview") }}
    </v-btn>
    <v-switch
      v-else
      class="pr-4 align-center primary"
      color="secondary"
      v-model="$store.state.base.darkTheme"
      label="Dark Theme"
    />
  </v-toolbar-items>
</template>

<script lang="js">
export default {
  name: 'HeaderMenu',
  computed: {
    navMenu () {
      if (this.$store.state.base.admin.isAdminMode) {
        return this.$store.state.base.admin.topAdminNav;
      } else {
        return this.$store.state.base.topNav;
      }
    },
    activeModuleUid () {
      return this.$store.state.base.curModuleUid;
    }
  },
  methods: {
    switchAdminMode () {
      this.$logger.debug('base', 'switch AdminMode: ', this.$store.state.base.admin.isAdminMode);
      let targetModule = this.$configHelper.getCurrentModuleUid();
      if (this.$store.state.base.admin.isAdminMode) {
        if (this.$configHelper.isModuleAvailable(targetModule + 'Admin')) {
          targetModule = targetModule + 'Admin';
        } else {
          targetModule = 'base';
        }
      } else {
        if (targetModule !== 'base') {
          targetModule = targetModule.substr(0, targetModule.length - 5);
        }
      }
      this.navigateToModule(targetModule);
    },
    async navigateToModule (moduleId, route = null) {
      this.$logger.debug('router', 'Navigate to module', moduleId, route);
      // if (moduleId !== this.$configHelper.getCurrentModuleUid()) {
      this.$configHelper.setCurrentModule(moduleId);

      if (route == null) {
        route = this.$configHelper.getDefaultRoute(moduleId);
      }

      this.$store.state.base.sidebar = this.$configHelper.getCurrentModuleSidebar();
      this.$router.push(route);

      // }
    },
    getIcon (moduleUid) {
      return this.$configHelper.getModuleConfigParam(moduleUid, 'nav.icon');
    },
    resetThemePreview () {
      this.$store.state.base.previewTheme = false;
      this.$store.state.base.admin.lockAdminOrgSelector = false;
      this.$vuetify.theme.dark = this.$store.state.base.admin.themes.darkTheme;
      let route = {
        path: '/accAdmin/accounts/' + this.$store.state.base.admin.themes.preview.orgUid,
        query: { tab: 'theme', resetPreview: 'true' }
      };
      this.navigateToModule('accAdmin', route);
    }
  }
};
</script>

<style scoped>
</style>
