
function tooltip (message, placement = 'auto', classes = []) {
  return {
    content: message,
    placement: placement,
    classes: classes,
    // targetClasses: ['it-has-a-tooltip'],
    offset: 5,
    delay: {
      show: 500,
      hide: 250
    }
  };
}

module.exports = tooltip;
