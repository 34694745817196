// plugins/filters.js
import Vue from 'vue';
import { store } from '@/plugins/store';
import DTH from '@/base/js/DateTimeHelper.js';

const formatTimePeriod = (value, forceSeconds = false, inclUnit = true) => {
  let timestring = '';
  if (value < 60 * 60 || forceSeconds) {
    // => 'mm:ss'
    let min = Math.floor(value / 60);
    let sec = value % 60;
    timestring = (min < 10 ? '0' + min : min) + ':' + (sec < 10 ? '0' + sec : sec);
    if (inclUnit) timestring += ' min';
  } else {
    // => 'hh:mm'
    let hours = Math.floor(value / 3600);
    let min = Math.round((value % 3600) / 60);
    timestring = (hours < 10 ? '0' + hours : hours) + ':' + (min < 10 ? '0' + min : min);
    if (inclUnit) timestring += ' h';
  }
  return timestring;
};

export default function filterInit () {
  // truncate filter
  Vue.filter('truncate', function (str, maxLen, suffix) {
    if (str.length <= maxLen) {
      return str;
    }
    return str.substr(0, str.lastIndexOf(' ', maxLen)) + suffix;
  });

  // date format filter
  Vue.filter('formatDateTime', function (value, nullValue) {
    if (DTH.isLaterOrEqual(value, DTH.INFINITEDATE)) return Vue.prototype.$t('base.unlimited');
    let ds = DTH.formatDateTime(value, nullValue);
    return ds;
  });

  // date format filter => 'DD.MM.YYYY'
  Vue.filter('formatDate', function (value, nullValue) {
    if (DTH.isLaterOrEqual(value, DTH.INFINITEDATE)) return Vue.prototype.$t('base.unlimited');
    let ds = DTH.formatDate(value, nullValue);
    return ds;
  });

  // time format filter  =>'hh:mm.ss'
  Vue.filter('formatTime', function (value, nullValue) {
    if (DTH.isLaterOrEqual(value, DTH.INFINITEDATE)) return Vue.prototype.$t('base.unlimited');
    let ds = DTH.formatTime(value, nullValue);
    return ds;
  });

  // seconds format filter => 'mm:ss'
  Vue.filter('formatSeconds', function (value) {
    return formatTimePeriod(value, true, false);
  });

  // time format filter => 'mm:ss min' | 'hh:mm h'
  Vue.filter('formatTimespan', function (value) {
    return formatTimePeriod(value);
  });

  Vue.filter('formatNumber', function (num, digits = null, nullValue) {
    if (num == null) return nullValue;
    let locale = store.state.base.curLocale;
    switch (locale) {
      default: locale = locale + '-' + locale.toUpperCase();
    }
    let options = {
      minimumFractionDigits: digits != null ? digits : 0,
      maximumFractionDigits: digits != null ? digits : 10
    };
    return num.toLocaleString(locale, options);
  });

  Vue.filter('inBrackets', function (value, nullValue) {
    if (value == null) {
      if (nullValue.length === 0) return nullValue;
      else value = nullValue;
    }
    return ' (' + value + ')';
  });

  Vue.filter('formatInput', function (input, type) {
    let v = null;
    if (input != null) {
      switch (type) {
        case 'percent':
        case 'float':
        case 'int':
          if (typeof input === 'string') {
            if (input !== '+' && input !== '-') { v = Number(input.replaceAll('.', '').replace(',', '.')); }
          } else { v = Number(input); }
          break;
        case 'upercent':
        case 'ufloat':
        case 'uint':
          if (typeof input === 'string') {
            v = Number(input.replaceAll('.', '').replace(',', '.'));
          } else { v = Number(input); }
          break;
        case 'text':
        default: v = input.trim(); break;
      }
    }
    return v;
  });
};
