import Vue from 'vue';
import axios from 'axios';

export const loadJsonFromUrl = async (url, mandatory = false) => {
  return new Promise(async (resolve, reject) => {
    axios.get(url).then((response) => {
      if (response.status === 200) {
        resolve(response.data);
      }
    }).catch((err) => {
      if (mandatory) {
        Vue.prototype.$logger.error('web', 'loadJsonFromUrl failed: ' + err.message, url);
        reject(err.message);
      } else {
        resolve(null);
      }
    });
  });
};
