import Vue from 'vue';
import { store } from '@/plugins/store';
import { loadJsonFromUrl } from '@/base/js/JsonLoader';
// import { S3Helper } from '@/base/js/S3Helper';

export async function loadTheme (themeOrg = null) {
  return new Promise(async (resolve, reject) => {
    if (themeOrg == null) {
      themeOrg = store.state.base.orgBaseSettings.theme;
    }

    let assets = Vue.prototype.$configHelper.getModuleConfigParam('acc', 'assets');
    let defaultTheme = Vue.prototype.$configHelper.getConfigParam('theme');
    loadJsonFromUrl(assets.url + assets.pathOrgTheme + themeOrg).then((theme) => {
      if (theme.dark) {
        // add defaultColors
        theme.dark.standardBg = defaultTheme.dark.standardBg;
        // theme.headerBg = defaultTheme.headerBg;
        // theme.headerBgContrast = defaultTheme.headerBgContrast;
        theme.dark.grey = defaultTheme.dark.grey;
        theme.dark.error = defaultTheme.dark.error;
        theme.dark.success = defaultTheme.dark.success;
        theme.dark.info = defaultTheme.dark.info;
      }
      if (theme.light) {
        // add defaultColors
        theme.light.standardBg = defaultTheme.light.standardBg;
        theme.light.grey = defaultTheme.light.grey;
        theme.light.error = defaultTheme.light.error;
        theme.light.success = defaultTheme.light.success;
        theme.light.info = defaultTheme.light.info;
      }
      resolve(theme);
    }).catch((e) => {
      reject(e);
    });
  });
};
