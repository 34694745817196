<template>
  <v-app>
    <div
      style="height: 100%;"
      v-if="$store.state.base.appPrepared"
    >
      <v-navigation-drawer
        v-if="$store.getters.orgReady && $store.state.base.sidebar.show"
        v-model="drawer"
        :key="$store.state.base.sidebar.moduleUid"
        :clipped="$vuetify.breakpoint.lgAndUp"
        fixed
        app
        class="sidebar"
      >
        <AdminOrgSelector
          class="mt-2"
          ref="adminSelector"
        />
        <v-divider class="my-2" />
        <Sidebar
          :elements="sidebar.elements"
          :moduleUid="sidebar.moduleUid"
        />
        <!-- sidebar-footer -->
        <template v-slot:append>
          <SidebarFooter />
        </template>
      </v-navigation-drawer>

      <v-app-bar
        clipped-left
        app
        elevation="0"
        color="white"
        class="topMenu"
      >
        <v-app-bar-nav-icon
          color="grey darken-3"
          class="ml-0 pl-3 pr-0"
          v-if="$store.getters.orgReady && $store.state.base.sidebar.show"
          @click.stop="drawer = !drawer"
        />
        <HeaderImage
          class="headerImage ml-0"
          :class="($store.getters.orgReady && $store.state.base.sidebar.show) ? 'part px-3' : 'full px-7'"
        />
        <HeaderMenu style="width: 100%;" />
        <!--<v-divider vertical />-->
        <UserMenu
          v-if="$store.getters.userReady"
          class="primary px-4"
        />
        <!--<v-divider vertical />-->
        <LocaleSelector class="primary pr-4" />
      </v-app-bar>
      <v-main class="main">
        <!--<AdminOrgSelector
          ref="adminSelector"
          class="primary"getCurrentModuleUid
        />-->
        <v-container
          v-if="($configHelper.isModuleAvailable($store.state.base.curModuleUid))"
          appwidth
          class="py-2 px-lg-12 px-xl-16 px-md-10 px-sm-8 px-xs-8 pb-5 mb-8"
          :class="$store.getters.orgReady ? 'd-flex text-left' : 'text-center justify-center'"
        >
          <router-view @checkAuth="checkAuth"></router-view>
        </v-container>
        <Footer />
      </v-main>
    </div>
    <ConfirmDlg ref="globalconfirm" />
    <InfoBar ref="globalinfo" />
    <Loader ref="loader" />
    <Debugger
      ref="debugger"
      v-if="$configHelper.getStage() === 'local' || $configHelper.getStage() === 'dev'"
    />
  </v-app>
</template>

<script lang="js">
import { loadTheme } from '@/base/js/ThemeLoader';
import { loadOrgSettings } from '@/base/js/baseHelper';
import { isProcessStateOK } from '@/base/js/ProcessDataHelper';
import { loadJsonFromUrl } from '@/base/js/JsonLoader';
import HeaderImage from '@/base/components/HeaderImage.vue';
import HeaderMenu from '@/base/components/HeaderMenu.vue';
import UserMenu from '@/base/components/UserMenu.vue';
import LocaleSelector from '@/base/components/LocaleSelector.vue';
import Sidebar from '@/base/components/Sidebar.vue';
import SidebarFooter from '@/base/components/SidebarFooter.vue';
import Footer from '@/base/components/Footer.vue';
import Loader from '@/base/components/Loader.vue';
import AdminOrgSelector from '@/baseAdmin/components/AdminOrgSelector';
import ConfirmDlg from '@/base/components/ConfirmDlg.vue';
import InfoBar from '@/base/components/InfoBar.vue';
import Debugger from '@/base/components/Debugger.vue';
const appConfig = require('@/.generic/appConfig.json');

/**
 * @vuedoc
 * @description the root vue component of this app
 */
export default {
  name: 'App',
  components: {
    HeaderImage,
    HeaderMenu,
    UserMenu,
    LocaleSelector,
    Sidebar,
    SidebarFooter,
    Footer,
    Loader,
    AdminOrgSelector,
    ConfirmDlg,
    InfoBar,
    Debugger
  },
  data () {
    return {
      drawer: true,
      miniVariant: false
    };
  },
  computed: {
    sidebar () {
      return this.$store.state.base.sidebar;
    }
  },
  watch: {
    '$store.state.base.appPrepared': function () {
      if (this.$store.state.base.appPrepared) {
        this.$globals.AdminSelector = this.$refs.adminSelector;
        this.$globals.Loader.hide();
        this.checkAuth(this.$store.state.base.defaultOrg);
      }
    },
    '$store.state.base.defaultOrg': async function () {
      console.error('loadOrgBaseSettings', this.$store.state.base.defaultOrg);
      await this.loadOrgBaseSettings();
    },
    '$store.state.base.darkTheme': function () {
      this.$vuetify.theme.dark = this.$store.state.base.darkTheme;
    },
    '$store.state.base.previewTheme': function () {
      if (!this.$store.state.base.previewTheme) this.loadOrgTheme(this.$store.state.base.orgBaseSettings.theme);
    }
  },
  async mounted () {
    this.$globals.Info = this.$refs.globalinfo;
    this.$globals.Confirm = this.$refs.globalconfirm;
    this.$globals.Loader = this.$refs.loader;
    this.$globals.Loader.show();
    if (this.$configHelper.getStage() === 'local' || this.$configHelper.getStage() === 'dev') this.$globals.Debugger = this.$refs.debugger;

    await this.loadOrgBaseSettings();
    let urlParams = new URLSearchParams(window.location.search);
    let invKey = urlParams.get('ik');
    if (invKey != null) {
      // user hat auf einladungs-email geklickt
      this.$router.push('/acc/register/' + encodeURIComponent(invKey));
    }
  },
  methods: {
    /**
   * @async
   * @method checkAuth
   * @description workflow  to check if user is signed in, initialized and org is initialized
   * @param {string} [selectedOrg=null] - uid of a selected org
   * @returns {void}
   * @memberof App.vue
   */
    async checkAuth (selectedOrg = null) {
      this.$logger.debug('router', 'Router resolve to ' + this.$router.getMatchedComponents(this.$route.fullPath)[0].name + ' (' + this.$route.fullPath + ')');

      let urlParams = new URLSearchParams(window.location.search);
      let invKey = urlParams.get('ik');

      if (this.$route.meta.noAuthRequired !== true && invKey == null) {
        this.$logger.debug('auth', 'App.checkAuth 1: org: ' + selectedOrg + '; userReady: ' + this.$store.getters.userReady + '; orgReady: ' + this.$store.getters.orgReady);
        if (this.$globals.Loader) this.$globals.Loader.show();
        // selectedOrg is the uid of an org set in chooseOrg
        try {
          let route = this.$sessionHelper.getLoginPath();

          if (typeof this.$store.state.base.firstRoute === 'string' && this.$store.state.base.firstRoute.startsWith('/acc/register')) route = this.$store.state.base.firstRoute;
          let processData = null;
          const authenticatedUser = await this.$sessionHelper.getAuthenticatedUser();
          if (authenticatedUser) {
            if (this.$store.getters.userReady && this.$store.getters.orgReady) {
              route = null;
            } else {
              let orgInitialized = false;
              if (!this.$store.getters.userReady) {
              // if user not ready, init user
                this.$logger.warn('auth', 'User not ready');
                processData = await this.$sessionHelper.initUser();
                if (isProcessStateOK(processData)) {
                  if (processData.ioData.targetOrg.uid != null) { // org will be delivered if preselected
                    selectedOrg = processData.ioData.targetOrg.uid;
                    orgInitialized = true;
                  }
                } else {
                  this.$globals.Info.open(processData.processState.messages);
                }
              }

              if (!this.$store.getters.userReady) {
              // if something went wrong do logout
                await this.$sessionHelper.signOut();
                route = this.$sessionHelper.getLoginPath();
              } else {
                this.$logger.debug('auth', 'App.checkAuth 2: org: ', selectedOrg, '; userReady: ', this.$store.getters.userReady, '; orgReady: ', this.$store.getters.orgReady);

                // else go on with organisation
                if (this.$store.getters.orgReady) { // => everything is fine
                  route = null;
                } else {
                  let listedOrg = null;
                  if (this.$store.state.base.orgList.length === 1) {
                    listedOrg = this.$store.state.base.orgList[0]; // init Org if user is only assigned to one org
                  } else if (selectedOrg) {
                    listedOrg = this.$store.state.base.orgList.find((o) => o.uid === selectedOrg);
                  } else if (this.$store.state.base.defaultOrg !== appConfig.defaultOrg) {
                    listedOrg = this.$store.state.base.orgList.find((o) => o.shortname === this.$store.state.base.defaultOrg);
                  } else {
                    route = '/chooseOrg';
                  }
                  this.$logger.warn('auth', 'org not ready ', JSON.stringify(listedOrg));
                  if (listedOrg) {
                    await this.presetOrg(listedOrg);
                    // check users state
                    switch (listedOrg.invitationState) {
                      case 'unknown':
                      case 'invited':
                      case 'unconfirmed':
                        route = '/acc/register';
                        break;
                      default: // case 'applied'
                        switch (listedOrg.state) {
                          case 'inactive':
                            route = '/inactive';
                            break;
                          default: // case 'active'
                            console.log('initOrg', orgInitialized);
                            if (orgInitialized) {
                              processData = await this.$sessionHelper.setOrganisation(processData);
                            } else {
                              processData = await this.$sessionHelper.initOrganisation(listedOrg.uid);
                            }
                            if (!isProcessStateOK(processData)) {
                              this.$globals.Info.open(processData.processState.messages);
                              route = '/chooseOrg';
                            } else {
                              route = null;
                            }
                        }
                    }
                  }
                }
                if (this.$store.getters.orgReady && this.$store.state.base.appPrepared) {
                  this.$store.state.base.sidebar = this.$configHelper.getCurrentModuleSidebar();
                }
              }
            }
          } else { // if (this.$sessionHelper.getSessionType() === 'acc') {
            await this.loadOrgBaseSettings();
          }
          if (!route) {
            if (this.$store.state.base.firstRoute.length > 1) {
              route = this.$store.state.base.firstRoute;
            } else route = '/';
          }
          if (route && route !== this.$route.path) {
            this.$logger.debug('router', 'checkAuth reroute to:', route);
            this.$router.push(route);
          }
        } catch (e) {
          this.$logger.error('auth', 'App.checkAuth:', e.message);
        } finally {
          if (this.$globals.Loader) this.$globals.Loader.hide();
        }
      }
    },
    async presetOrg (org) {
      this.$store.state.base.org.uid = org.uid;
      this.$store.state.base.org.shortname = org.shortname;
      await this.loadOrgBaseSettings(org.shortname);
      this.$store.state.base.orgSettings = await loadOrgSettings(org.uid);
    },
    async loadOrgBaseSettings (org = null) {
      if (org == null) org = this.$store.state.base.defaultOrg;
      let assets = this.$configHelper.getModuleConfigParam('acc', 'assets');
      let orgBaseSettings = await loadJsonFromUrl(assets.url + assets.pathOrgBaseSettings + org + '.json');
      if (orgBaseSettings == null) {
        this.$store.state.base.defaultOrg = appConfig.defaultOrg;
        this.$store.state.base.orgBaseSettings = await loadJsonFromUrl(assets.url + assets.pathOrgBaseSettings + appConfig.defaultOrg + '.json');
      } else {
        this.$store.state.base.orgBaseSettings = orgBaseSettings;
      }
      await this.loadOrgTheme(this.$store.state.base.orgBaseSettings.theme);
    },
    async loadOrgTheme (theme) {
      let t = await loadTheme(theme);
      this.$vuetify.theme.setTheme('light', t.light);
      this.$vuetify.theme.setTheme('dark', t.dark);
    }
  }
};
</script>

<style scoped>
.main {
  height: 100%;
  background-color: var(--v-standardBg-base);
}
</style>
