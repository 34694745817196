<template>
  <v-footer
    padless
    absolute
    class="grey lighten-4 px-2 py-0 darkText"
  >
    <div class="caption mr-1">
      &copy; {{ new Date().getFullYear() }} -
      powered by
    </div>
    <v-img
      :src="orgLogo"
      contain
      position="0 center"
      height="20px"
      max-width="90px"
    />
    <!--<span class="font-italic font-weight-bold">eurobase</span>-->
    <div class="caption mr-4">
      {{ getAppVersion() }}
    </div>
    <!--<v-btn
      v-for="(link, i) in links"
      :href="link.xpath"
      target="_blank"
      class="caption darkText"
      :key="i"
      text
    >
      {{ $t(link.text) }}
    </v-btn>-->
    <v-btn
      v-for="(link, i) in links"
      :to="link.path"
      class="caption darkText"
      :key="i"
      text
    >
      {{ $t(link.text) }}
    </v-btn>
    <v-btn
      v-if="$configHelper.getStage() === 'local' || $configHelper.getStage() === 'dev'"
      @click="$globals.Debugger.show()"
      class="error--text caption"
      text
    >
      {{ $t('base.components.debugger') }}
    </v-btn>
    <v-spacer />
    <a
      :href="mailContact"
      class="mailContact caption"
    >
      {{ $t('base.footer.footerQuestion') }}
      <v-icon class="body-1 darkIcon">mdi-help-circle</v-icon>
    </a>
  </v-footer>
</template>

<script lang="js">
export default {
  name: 'Footer',
  data () {
    return {
      links: [
        // {
        //   text: 'base.footer.versionNotes',
        //   icon: '',
        //   path: 'https://www.video2know.com/versionshinweise'
        // },
        {
          text: 'base.footer.privacy',
          icon: '',
          path: '/privacy'
        },
        {
          text: 'base.footer.imprint',
          icon: '',
          xpath: 'https://www.eurobase.lu/impressum/',
          path: '/imprint'
        }
      ]
    };
  },
  computed: {
    mailContact () {
      return 'mailto:' + this.$store.state.base.orgBaseSettings.contact;
    },
    orgLogo () {
      return this.$configHelper.getResourcesPath() + 'img/logo_eurobase.svg';
    }
  },
  methods: {
    getAppVersion () {
      return 'v' + this.$configHelper.getConfigParam('appVersion');
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mailContact {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
