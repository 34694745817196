import Vue from 'vue';
import axios from 'axios';

export class ImageHelper {
  static getImage (imageURL) {
    Vue.prototype.$logger.debug('web', 'Getting image from: ' + imageURL);

    return new Promise((resolve) => {
      let base64img = null;
      axios.get(imageURL, { responseType: 'arraybuffer' })
        .then((response) => {
          if (response.status === 200) {
            let mediaType = response.headers['content-type'];
            const data = response.data;

            if (!data || !mediaType) {
              Vue.prototype.$logger.error('web', 'ImageDataURI :: Error :: Missing some of the required params: data, mediaType ');
              resolve(base64img);
            }
            base64img = this.img2base64(data, mediaType);
          } else {
            Vue.prototype.$logger.error('web', 'encodeImage-Error: GET -> ' + imageURL + ' returned ' + response.statusCode);
          }
          resolve(base64img);
        }).catch((e) => {
          Vue.prototype.$logger.error('web', 'encodeImage-Error: ' + e.message, imageURL);
          resolve(base64img);
        });
    });
  }

  static img2base64 (data, mediaType) {
    mediaType = (/\//.test(mediaType)) ? mediaType : 'image/' + mediaType;
    const base64img = 'data:' + mediaType + ';base64, ' + Buffer.from(data).toString('base64');

    // Vue.prototype.$logger.debug('web', 'encoded image:', base64img);
    return base64img;
  }

  static async resizeBase64Image (base64Image, maxHeight = 256, maxWidth = 256) {
    return new Promise((resolve) => {
      let img = new Image();
      img.src = base64Image;
      img.onload = () => {
        let canvas = document.createElement('canvas');
        const MAX_WIDTH = maxWidth;
        const MAX_HEIGHT = maxHeight;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL('image/jpeg'));
      };
    });
  }
};
