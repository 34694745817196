<template>
  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
    top
    tile
    color="white"
    min-width="800px"
    max-width="max-content"
    class="infoBar pa-0"
  >
    <v-row
      no-gutters
      v-for="(message, i) in messages"
      :key="i"
      class="my-0"
      :class="getColor(message.type, '--text')"
    >
      <v-icon :class="getColor(message.type, '--text mr-4 small')">
        {{ getIcon(message.type) }}
      </v-icon>
      <span class="">{{ getMessage(message) }}</span>
    </v-row>
    <template v-slot:action="{ attrs }">
      <v-btn
        @click="close()"
        v-bind="attrs"
        icon
        class="grey--text text--darken2"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="js">
/**
 * Vuetify snackbar component
 *
 * Insert component where you want to use it:
 * <info-bar ref="info" />
 *
 * Call it:
 * this.$refs.info.open(messages:string[])
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$info
 * <template>
 *   <v-app>
 *     ...
 *    <info-bar ref="info" />
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$info = this.$refs.info
 * }
 */
/*
interface message {
    type: number;
    message: string;
}
*/
export default {
  name: 'InfoBar',
  data () {
    return {
      snackbar: false,
      messages: [], // Array<{type:string, message:string}>
      timeout: 4000,
      showSysMsg: false
    };
  },
  methods: {
    open (messages, showSysMsg = false, prefix = null) {
      if (!this.snackbar) {
        this.messages = [];
      }
      this.showSysMsg = showSysMsg;
      for (let m of messages) {
        if (typeof m.message !== 'undefined' && m.message && m.message.length > 0) {
          this.messages.push(m);
          // on error-messages infobar has to be closed manually; other messages disappear after a timeout (in ms)
          if (m.type !== 'warning' && m.type !== 'success' && m.type !== 'info') {
            this.timeout = 0;
          }
          this.snackbar = true;
        }
      }
    },

    close () {
      this.snackbar = false;
      this.messages = [];
      this.timeout = 4000;
      this.showSysMsg = false;
    },

    getMessage (msg) {
      if (this.showSysMsg && msg.sysMsg != null && msg.sysMsg.length > 0) return this.$t(msg.message) + ': ' + msg.sysMsg;
      return this.$t(msg.message);
    },

    getIcon (type) {
      switch (type) {
        case 'success':
          return 'mdi-check';
        case 'warning':
          return 'mdi-alert-circle-outline';
        case 'userError':
          return 'mdi-alert-outline';
        case 'systemError':
          return 'mdi-flash-alert-outline';
        case 'networkError':
          return 'mdi-lan-disconnect';
        case 'info':
        default:
          return 'mdi-information-outline';
      }
    },

    getColor (type, postfix = '') {
      switch (type) {
        case 'success':
          return 'success' + postfix;
        case 'warning':
          return 'warning' + postfix;
        case 'userError':
        case 'systemError':
        case 'networkError':
          return 'error' + postfix;
        case 'info':
        default:
          return 'text--lighten-3 info' + postfix;
      }
    }
  }
};
</script>

<style scoped>
</style>
