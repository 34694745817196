// import Vue from 'vue';
import { AdminObject } from '@/base/struct/AdminObject';
// import { loadTextPackage } from '@/base/js/TextHelper';

export default async function appPrepare (store, configHelper, logger, router) {
  try {
    store.state.base.topNav.length = 0;
    store.state.base.admin = new AdminObject();
    await configHelper.init();
    store.state.base.sidebar = configHelper.getCurrentModuleSidebar();
    store.state.base.appPrepared = true;
    logger.debug('config', 'App is prepared');
  } catch (ex) {
    logger.error('config', 'PrepareApp failed: ' + ex.message);
  }
}
