import Vue from 'vue';
import { loadJsonFromUrl } from '@/base/js/JsonLoader';
import { store } from '@/plugins/store';
// import { S3Helper } from '@/base/js/aws/S3Helper';
const appConfig = require('@/.generic/appConfig.json');

export const loadTextPackage = async (moduleId, locale = 'de') => {
  return new Promise(async (resolve) => {
    if (!isDictionaryLoaded(moduleId, locale)) {
      // axios.get('/texts/' + moduleId + '_' + locale + '.json').then((response) => {
      loadJsonFromUrl('/res/' + appConfig.buildId + '/texts/' + moduleId + '_' + locale + '.json').then((response) => {
        Vue.i18n.add(locale, response);
        resolve(1);
      }).catch((err) => {
        let fallback = appConfig.locales.fallback;
        if (fallback !== locale && !isDictionaryLoaded(moduleId, fallback)) {
          // try to load fallback-Package
          Vue.prototype.$logger.warn('web', 'loading fallback-text!' + moduleId + '_' + locale);
          loadJsonFromUrl('/res/' + appConfig.buildId + '/texts/' + moduleId + '_' + locale + '.json').then((response) => {
            Vue.i18n.add(locale, response);
            resolve(1);
          }).catch((err) => {
            Vue.prototype.$logger.error('web', 'loadTextPackage (fallback): ' + err.message);
            resolve(-1);
          });
        } else {
          Vue.prototype.$logger.error('web', 'loadTextPackage: ' + err.message);
          resolve(-1);
        }
      });
    } else {
      resolve(0);
    }
  });
};

const isDictionaryLoaded = (moduleId, locale) => {
  const packageId = moduleId + '.packageId';
  if (typeof store.state.i18n.translations[locale] === 'undefined' ||
    typeof store.state.i18n.translations[locale][packageId] === 'undefined') {
    return false;
  } else {
    return true;
  }
};
