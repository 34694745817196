export default function deviceInit (store) {
  const device = {
    windows: false,
    windowsPhone: false,
    ipod: false,
    ipad: false,
    iphone: false,
    ios: false,
    mac: false,
    android: false,
    androidPhone: false,
    mobile: false
  };
  // set Device Properties
  if (window.navigator && window.navigator.userAgent) {
    let userAgent = window.navigator.userAgent.toLowerCase();
    device.windows = userAgent.indexOf('windows') !== -1;
    device.windowsPhone = device.windows && userAgent.indexOf('mobile') !== -1;
    device.ipod = userAgent.indexOf('ipod') !== -1;
    device.ipad = userAgent.indexOf('ipad') !== -1 && userAgent.indexOf('webkit') !== -1;
    device.iphone = !device.windows && userAgent.indexOf('iphone') !== -1 && !device.ipad && !device.ipod;
    device.ios = device.iphone || device.ipad || device.ipod;
    device.mac = userAgent.indexOf('macintosh') !== -1;
    device.android = userAgent.indexOf('android') !== -1;
    device.androidPhone = device.android && userAgent.indexOf('mobile') !== -1;
    device.mobile = device.androidPhone || device.iphone || device.ipod || device.windowsPhone;
  }

  return device;
};
