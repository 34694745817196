<template>
  <v-dialog
    v-model="dialog"
    light
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title class="px-4 py-2 primary-bg">
        {{ title }}
      </v-card-title>
      <v-card-text
        v-show="!!message"
        class="pa-4"
        v-html="message"
      >
        <!--{{ message }}-->
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="btnCancel"
          class="prio2"
          @click.native="onCancel"
        >
          {{ textCancel || $t('base.cancel') }}
        </v-btn>
        <v-btn
          v-if="btnOK"
          @click.native="onOK"
          color="secondary"
        >
          {{ textOK || $t('base.ok') }}
        </v-btn>
        <v-btn
          v-if="btnNo"
          @click.native="onNo"
          color="secondary"
        >
          {{ textNo || $t('base.no') }}
        </v-btn>
        <v-btn
          v-if="btnYes"
          @click.native="onYes"
          color="secondary"
        >
          {{ textYes || $t('base.yes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="js">
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 *  this.$refs.confirm.yesNoDlg('Delete', 'Are you sure?').then((answer) => {})
 * Or use await:
 *  if (await this.$refs.confirm.yesNoDlg('Delete', 'Are you sure?')) {
 *    // yes
 * }
 * else {
 *   // no
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm-dlg ref="confirm" />
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm
 * }
 */
export default {
  name: 'ConfirmDlg',
  data () {
    return {
      dialog: false,
      resolve: null,
      reject: null,

      title: '',
      message: '',
      btnOK: false,
      btnYes: false,
      btnNo: false,
      btnCancel: false,
      textOK: null,
      textYes: null,
      textNo: null,
      textCancel: null
    };
  },
  methods: {
    okDlg (title, message, textOK = null) {
      this.dialog = true;
      this.btnOK = true;
      this.title = title;
      this.message = this.format(message);
      this.textOK = textOK;

      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },

    okCancelDlg (title, message, textOK = null, textCancel = null) {
      this.dialog = true;
      this.btnOK = true;
      this.btnCancel = true;
      this.title = title;
      this.message = this.format(message);
      this.textOK = textOK;
      this.textCancel = textCancel;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    yesNoDlg (title, message, textYes = null, textNo = null) {
      this.dialog = true;
      this.btnYes = true;
      this.btnNo = true;
      this.title = title;
      this.message = this.format(message);
      this.textYes = textYes;
      this.textNo = textNo;

      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },

    yesNoCancelDlg (title, message, textYes = null, textNo = null, textCancel = null) {
      this.dialog = true;
      this.btnYes = true;
      this.btnNo = true;
      this.btnCancel = true;
      this.title = title;
      this.message = this.format(message);
      this.textYes = textYes;
      this.textNo = textNo;
      this.textCancel = textCancel;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    onYes () {
      this.resolve(true);
      this.close();
    },

    onNo () {
      this.resolve(false);
      this.close();
    },

    onOK () {
      this.resolve(true);
      this.close();
    },

    onCancel () {
      this.reject(false);
      this.close();
    },

    close () {
      this.dialog = false;
      this.btnOK = false;
      this.btnYes = false;
      this.btnNo = false;
      this.btnCancel = false;
      this.resolve = null;
      this.reject = null;
      this.textOK = null;
      this.textYes = null;
      this.textNo = null;
      this.textCancel = null;
    },

    format (message) {
      return message.replaceAll('\n', '<br/>');
    }
  }
};
</script>

<style scoped>
.v-btn {
  min-width: 64px !important;
}
</style>
