import { accHelper } from '@/acc/js/accHelper';
import { AdminObject } from '@/base/struct/AdminObject';
import { BaseSidebar } from '@/base/struct/BaseSidebar';

const state = () => ({
  debug: false,
  appPrepared: false,
  signedInState: 0,
  user: accHelper.copyUser(),
  org: accHelper.copyOrganisation(),
  orgSettings: null,
  orgList: [],
  curLocale: 'de',
  curModuleUid: 'base',
  firstRoute: '',
  darkTheme: false,
  previewTheme: false,
  topNav: [],
  sidebar: new BaseSidebar(),
  defaultOrg: null,
  preSelectedOrg: null,
  orgBaseSettings: {},
  admin: new AdminObject()
});

const getters = {
  userReady: (state) => {
    return state.signedInState > 1;
  },
  orgReady: (state) => {
    return state.signedInState > 2;
  }
};

const mutations = {
  signOut (state) {
    state.user = accHelper.copyUser();
    state.orgList = [];
    state.topNav = [];
    state.topAdminNav = [];
    state.org = accHelper.copyOrganisation();
    state.curModuleUid = 'base';
    state.signedInState = 0;
    state.admin = new AdminObject();
    state.orgSettings = null;
    state.preSelectedOrg = null;
    state.orgBaseSettings = {};
  },
  resetOrg (state) {
    state.topNav = [];
    state.topAdminNav = [];
    state.org = accHelper.copyOrganisation();
    state.curModuleUid = 'base';
    state.signedInState = 2;
    state.admin = new AdminObject();
    state.orgSettings = null;
    state.firstRoute = '/';
    state.preSelectedOrg = null;
    state.orgBaseSettings = {};
  },
  setLanguage (state, lang) {
    state.curLocale = lang;
  }
};

export const baseStore = {
  state: state,
  mutations: mutations,
  getters: getters
};
