const _NULLVALUE = '-';
const _BASEDATE = '1970-01-01';
const _INFINITEDATE = '2070-12-31 00:00:00';
const _INFINITEDATETIME = '2070-12-31 23:59:59';

export default class DateTimeHelper {
  static get BASEDATE () {
    return _BASEDATE;
  }

  static get INFINITEDATE () {
    return _INFINITEDATE;
  }

  static get INFINITEDATETIME () {
    return _INFINITEDATETIME;
  }

  static get NOW () {
    return this.formatSqlDateTime(null);
  }

  static preFormatSqlDateTime (input) {
    if (input == null) return new Date();
    else if (typeof input === 'string') {
      if (!input.endsWith('Z')) input += 'Z';
      return new Date(input);
    } else {
      return input;
    }
  }

  static formatSqlDateTime (input, useTime = true) {
    let d = this.preFormatSqlDateTime(input);
    let v = d.toISOString().substring(0, 10) + (useTime ? ' ' + d.toISOString().substring(11, 17) + '00' : '');
    return v >= _INFINITEDATE ? _INFINITEDATETIME : v;
  }

  static formatSqlDateTimeBefore (input) {
    let d = this.preFormatSqlDateTime(input);
    d.setMinutes(d.getMinutes() - 1);
    return this.formatSqlDateTime(d);
  }

  static formatSqlDateTimeAfter (input) {
    let d = this.preFormatSqlDateTime(input);
    d.setMinutes(d.getMinutes() + 1);
    return d >= _INFINITEDATE ? _INFINITEDATETIME : this.formatSqlDateTime(d);
  }

  static formatLocaleString (value, nullValue = _NULLVALUE, options = {}, locale = 'de-DE') {
    if (value == null) return nullValue;
    if (typeof value === 'string' && !value.endsWith('Z')) value += 'Z';
    if (new Date(value) >= new Date(_INFINITEDATE)) value = _INFINITEDATETIME;
    return new Date(value).toLocaleString(locale, options);
  }

  // date format filter => 'DD.MM.YYYY hh:mm:ss'
  static formatDateTime (value, nullValue = _NULLVALUE) {
    return this.formatLocaleString(value, nullValue, { timeZone: 'Europe/Berlin', dateStyle: 'medium', timeStyle: 'short' });
  }

  // date format filter => 'DD.MM.YYYY'
  static formatDate (value, nullValue = _NULLVALUE) {
    return this.formatLocaleString(value, nullValue, { timeZone: 'Europe/Berlin', dateStyle: 'medium' });
  }

  // time format filter  =>'hh:mm.ss'
  static formatTime (value, nullValue = _NULLVALUE) {
    return this.formatLocaleString(value, nullValue, { timeZone: 'Europe/Berlin', timeStyle: 'short' });
  }

  static isEqual (d1, d2 = null) {
    return this.formatSqlDateTime(d1) === this.formatSqlDateTime(d2);
  }
  static isNotEqual (d1, d2 = null) {
    return this.formatSqlDateTime(d1) !== this.formatSqlDateTime(d2);
  }
  static isEarlier (d1, d2 = null) {
    return this.formatSqlDateTime(d1) < this.formatSqlDateTime(d2);
  }
  static isLater (d1, d2 = null) {
    return this.formatSqlDateTime(d1) > this.formatSqlDateTime(d2);
  }
  static isEarlierOrEqual (d1, d2 = null) {
    return this.formatSqlDateTime(d1) <= this.formatSqlDateTime(d2);
  }
  static isLaterOrEqual (d1, d2 = null) {
    return this.formatSqlDateTime(d1) >= this.formatSqlDateTime(d2);
  }
}
