<template>
  <v-dialog
    v-model="showDebugger"
    persistent
  >
    <Headline
      title="Debugger"
      icon="mdi-file-code"
      :backButton="true"
      :backText="$t('base.close')"
      css="grey lighten-3 pa-4"
      @back="hide()"
    />
    <v-card>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            class="my-2"
          >
            <v-btn
              @click.native="hide()"
              color="primary"
              class="mx-2"
            >
              <v-icon>mdi-close-box</v-icon>
            </v-btn>
            <v-btn
              @click.native="goHome()"
              color="error"
              class="mx-2"
            >
              <v-icon>mdi-home</v-icon>
            </v-btn>
            <v-btn
              @click.native="logout()"
              color="error"
              class="mx-2"
            >
              <v-icon>mdi-power</v-icon>
            </v-btn>
            <v-btn
              @click.native="resetCookies()"
              color="error"
              class="mx-2"
            >
              reset Cookies
            </v-btn>
            <v-btn
              @click.native="showCookies()"
              color="secondary"
              class="mx-2"
            >
              Cookies ...
            </v-btn>
            <v-btn
              @click.native="showState()"
              color="secondary"
              class="mx-2"
            >
              State
            </v-btn>
            <v-btn
              @click.native="showModuleConfig()"
              color="secondary"
              class="mx-2"
            >
              ModuleConfigs
            </v-btn>
            <v-btn
              @click.native="showDictionary()"
              color="secondary"
              class="mx-2"
            >
              Dictionary
            </v-btn>
            <v-btn
              @click.native="showRoutes()"
              color="secondary"
              class="mx-2"
            >
              Routes
            </v-btn>
            <v-btn
              @click.native="checkUser()"
              color="secondary"
              class="mx-2"
            >
              User
            </v-btn>
            <v-btn
              @click.native="showVuetifyInfo()"
              color="secondary"
              class="mx-2"
            >
              Vuetify
            </v-btn>
            <v-btn
              @click.native="showLogger()"
              color="secondary"
              class="mx-2"
            >
              Logger
            </v-btn>
            <v-btn
              @click.native="showInfoBar()"
              color="primary"
              class="mx-2"
            >
              InfoBar
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mx-1">
            <v-jsoneditor
              v-model="jsonData"
              :plus="true"
              height="600px"
              @error="onError"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="js">
import { removeCookie, getAllCookies } from 'tiny-cookie';
import { JwtHelper } from '@/base/js/JwtHelper';
import Headline from '@/common/Headline.vue';
import VJsoneditor from 'v-jsoneditor/src/index';

export default {
  name: 'Debugger',
  components: {
    Headline,
    VJsoneditor
  },
  data () {
    return {
      showDebugger: false,
      jsonData: '',
      label: '',
      state: 'state' // state, cookies, modules, dictionary, routes, user, vuetify
    };
  },
  methods: {
    show () {
      this.showDebugger = true;
      switch (this.state) {
        case 'state': this.showState(); break;
        case 'cookies': this.showCookies(); break;
        case 'modules': this.showModuleConfig(); break;
        case 'dictionary': this.showDictionary(); break;
        case 'routes': this.showRoutes(); break;
        case 'user': this.checkUser(); break;
        case 'vuetify': this.showVuetifyInfo(); break;
      }
    },
    hide () {
      this.jsonData = '';
      this.label = '';
      this.showDebugger = false;
    },
    goHome () {
      this.hide();
      if (this.$store.getters.orgReady) {
        this.$router.push('/');
      } else if (this.$store.getters.userReady) {
        this.$router.push('/chooseOrg');
      } else {
        this.$router.push(this.$sessionHelper.getLoginPath());
      }
    },
    logout () {
      this.$sessionHelper.signOut();
    },
    resetCookies () {
      removeCookie('locale');
      removeCookie('org');
      removeCookie('auth');
    },
    showCookies () {
      let cookies = getAllCookies();
      let locals = {};
      for (let i = 0; i < localStorage.length; i++) {
        locals[localStorage.key(i)] = localStorage.getItem(localStorage.key(i));
      }
      this.jsonData = {
        cookies: cookies,
        localStorage: locals
      };
    },
    showState () {
      this.label = 'State';
      let debugData = JSON.parse(JSON.stringify(this.$store.state));
      if (debugData.base.user.avatarBin) {
        debugData.base.user.avatarBin = debugData.base.user.avatarBin.split(',')[0];
      }
      if (debugData.base.org.logo) {
        debugData.base.org.logo = debugData.base.org.logo.split(',')[0];
      }
      this.jsonData = debugData;
    },

    showModuleConfig () {
      this.label = 'Module Configurations';
      this.jsonData = {
        appConfig: this.$configHelper.getAppConfig(),
        currentModuleUid: this.$configHelper.getCurrentModuleUid(),
        baseModules: this.$configHelper.getBaseModules()
      };
    },

    showDictionary () {
      this.label = 'Dictionary';
      this.jsonData = this.$store.state.i18n;
    },

    showRoutes () {
      let routes = this.$router.options.routes;
      this.jsonData = {};
      for (let route of routes) {
        this.jsonData[route.path] = route;
      }
    },

    showLogger () {
      this.label = 'Logging Configurations';
      this.jsonData = this.$logger._config;
    },

    showVuetifyInfo () {
      this.jsonData = {
        themes: this.$vuetify.theme.themes,
        lang: this.$vuetify.lang.current
      };
    },
    async checkUser () {
      if (this.$store.state.base.user.uid === null) {
        this.jsonData = { state: 'NO USER LOGGED IN TO CHECK !!!' };
        return;
      }
      let token = await this.$sessionHelper.getAccToken();
      if (typeof token !== 'string') {
        let expDate = new Date(token.getExpiration() * 1000);
        let nowDate = new Date();
        let jwtToken = await this.$sessionHelper.getAccJwtToken();
        let debugData = {
          jwtHeader: JwtHelper.decodeTokenHeader(jwtToken),
          jwtData: JwtHelper.decodeTokenioData(jwtToken),
          storedAccToken: token.getJwtToken(),
          now: nowDate.toString(),
          validUntil: expDate.toString()
        };
        this.jsonData = debugData;
      } else {
        this.jsonData = { token: token };
      }
    },
    showInfoBar () {
      let messages = [
        { type: 'info', message: 'This is an info-message' },
        { type: 'success', message: 'This is a  success message. Everything is ok.' },
        { type: 'warning', message: 'This is a warning' },
        {
          type: 'userError',
          message: 'This is an user error. Please correct your input and try again.'
        },
        { type: 'systemError', message: 'This is a system error. I don\'t know what to do.' },
        {
          type: 'networkError',
          message: 'This is a network error. Possibly your connection is lost. '
        }
      ];
      this.$globals.Info.open(messages);
    },
    onError () {
      this.$logger.error('default', 'JSON-Editor error');
    }
  }
};
</script>

<style scoped>
</style>
