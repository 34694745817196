// baseAdminHelper.js
import Vue from 'vue';
import { store } from '@/plugins/store';
import { isProcessStateOK } from '@/base/js/ProcessDataHelper';
import { loadOrgSettings, completeSettings, orgSettingsTemplate } from '@/base/js/baseHelper';
import { accHelper } from '@/acc/js/accHelper';

export const getAdministratableOrg = (targetOrgUid) => {
  let org = store.state.base.admin.administratableOrgs.find((org) => org.uid === targetOrgUid);
  if (typeof org === 'undefined') {
    org = null;
  }
  return org;
};

export const loadAdministratableOrgList = async (targetOrgUid = null) => {
  store.state.base.admin.loadingOrgs = true;
  const ioData = {};
  if (targetOrgUid != null) ioData.targetOrgUid = targetOrgUid;

  return new Promise(async (resolve, reject) => {
    Vue.prototype.$restClient.callProcess('baseAdmin', 'organisationGetTree', ioData).then(
      (processData) => {
        if (isProcessStateOK(processData)) {
          const flattenedTree = [];
          flattenTree(flattenedTree, processData.ioData.orgTree);
          store.state.base.admin.administratableOrgs = flattenedTree;
          resolve(flattenedTree);
        } else {
          store.state.base.admin.accountOrgList = [];
          reject(processData.processState.messages);
        }
      }
    ).finally(() => {
      store.state.base.admin.loadingOrgs = false;
    });
  });
};

export const loadUserList = async (targetOrgUid) => {
  store.state.base.admin.loadingUsers = true;
  const ioData = {
    targetOrg: targetOrgUid
  };

  return new Promise(async (resolve, reject) => {
    Vue.prototype.$restClient.callProcess('baseAdmin', 'userGetList', ioData).then(
      (processData) => {
        if (isProcessStateOK(processData)) {
          store.state.base.admin.administratedOrgObjects.userList = processData.ioData.userList;
          for (let user of store.state.base.admin.administratedOrgObjects.userList) {
            // set mainstate out of state and invitationState
            user.mainstate = user.invitationState !== 'applied' ? user.invitationState : user.state;
          }
          resolve(processData.ioData.userList);
        } else {
          store.state.base.admin.administratedOrgObjects.userList = [];
          reject(processData.processState.messages);
        }
      }
    ).finally(() => {
      store.state.base.admin.loadingUsers = false;
    });
  });
};

export const loadGroupList = async (targetOrgUid) => {
  store.state.base.admin.loadingGroups = true;
  const ioData = {
    targetOrg: targetOrgUid
  };

  return new Promise(async (resolve, reject) => {
    Vue.prototype.$restClient.callProcess('baseAdmin', 'userGroupGetList', ioData).then(
      (processData) => {
        if (isProcessStateOK(processData)) {
          store.state.base.admin.administratedOrgObjects.groupList = processData.ioData.groupList;
          let defaultGroup = processData.ioData.groupList.find((g) => g.name === 'allUser');
          Vue.prototype.$logger.debug('base', 'defaultGroup: ', JSON.stringify(defaultGroup));
          if (typeof defaultGroup !== 'undefined') {
            store.state.base.admin.administratedOrgObjects.defaultGroup = defaultGroup.uid;
          } else {
            store.state.base.admin.administratedOrgObjects.defaultGroup = null;
          }
          resolve(processData.ioData.userList);
        } else {
          store.state.base.admin.administratedOrgObjects.groupList = [];
          store.state.base.admin.administratedOrgObjects.defaultGroup = null;
          reject(processData.processState.messages);
        }
      }
    ).finally(() => {
      store.state.base.admin.loadingGroups = false;
    });
  });
};

export const loadRoleList = async (targetOrgUid) => {
  const ioData = {
    targetOrg: targetOrgUid,
    rolesAndGroups: true
  };

  store.state.base.admin.administratedOrgObjects.roleList = [];

  return new Promise(async (resolve, reject) => {
    Vue.prototype.$restClient.callProcess('baseAdmin', 'organisationGet', ioData).then(
      (processData) => {
        if (isProcessStateOK(processData)) {
          for (let role of processData.ioData.availableRoles) {
            if (role.uid !== 'basicUser') {
              store.state.base.admin.administratedOrgObjects.roleList.push(role);
            }
          }
          resolve(processData.ioData.availableRoles);
        } else {
          reject(processData.processState.messages);
        }
      }
    );
  });
};

const flattenTree = (flattenedTree, tree, treestring = '', depth = 0, parentId = '', parent = '', parentShort = '') => {
  for (const o of tree) {
    const org = accHelper.copyOrganisation(o);
    org.treeParams = {
      show: depth < 3,
      expandable: o.subOrgs.length > 0,
      expanded: depth < 2 && o.subOrgs.length > 0,
      treestring: treestring + org.shortname + '_',
      parent: parent,
      parentId: parentId,
      parentShort: parentShort,
      depth: depth
    };
    // org.subOrgs.length > 0 ? org.expandable = true : org.expandable = false;
    // depth > 1 ? org.show = false : org.show = true;
    // depth >= 1 ? org.expanded = false : org.expanded = org.expandable;

    // org.treestring = treestring + org.shortname + '_';
    // org.parent = parent;
    // org.parentId = parentId;
    // org.depth = depth;
    flattenedTree.push(org);
    flattenTree(flattenedTree, o.subOrgs, org.treeParams.treestring, depth + 1, org.uid, org.name, org.shortname);
  }
};

export const setAdministratedOrgList = (rootOrg, fullOrgList) => {
  if (rootOrg.shortname === store.state.base.org.shortname) {
    store.state.base.admin.administratedOrgObjects.orgList = fullOrgList;
  } else {
    let baseDepth = rootOrg.treeParams.depth + 1;
    rootOrg.treeParams.show = true;
    if (rootOrg.treeParams.expandable) rootOrg.treeParams.expanded = true;

    store.state.base.admin.administratedOrgObjects.orgList = [];
    for (let org of fullOrgList) {
      if (org.treeParams.treestring.startsWith(rootOrg.shortname + '_') || org.treeParams.treestring.includes('_' + rootOrg.shortname + '_')) {
        if (org.treeParams.depth <= baseDepth + 1) org.treeParams.show = true;
        if (org.treeParams.depth === baseDepth) org.treeParams.show = true;
        store.state.base.admin.administratedOrgObjects.orgList.push(org);
      }
    }
  }
};

export const reloadOrgList = async (org = store.state.base.org.uid) => {
  return new Promise(async (resolve) => {
    // load administrateableOrgList
    loadAdministratableOrgList().then(
      (orgList) => {
        // set administratedOrgObjects.orgList
        setAdministratedOrgList(org, orgList);
        resolve(orgList);
      }
    );
  });
};

export const initAdministratedOrg = async (org = store.state.base.org.uid) => {
  // set administrated Org
  store.state.base.admin.administratedOrg = org;
  // load administratedOrgObjects.orgSettings
  store.state.base.admin.administratedOrgObjects.orgSettings = await loadOrgSettings(org.uid);
  // load administrateableOrgList
  loadAdministratableOrgList().then(
    (orgList) => {
      // set administratedOrgObjects.orgList
      setAdministratedOrgList(org, orgList);
    }
  );
  if (Vue.prototype.$configHelper.isModuleAvailable('accAdmin')) {
    // load administratedOrgObjects.userList
    loadUserList(org.uid);
    // load administratedOrgObjects.groupList
    loadGroupList(org.uid);
  }
  // load administratedOrgObjects.moduleSettings
  loadModuleOrgSettings(org.uid);
};

export const setAdministratedOrg = async (org) => {
  // set administrated Org
  store.state.base.admin.administratedOrg = org;
  // load administratedOrgObjects.orgSettings
  store.state.base.admin.administratedOrgObjects.orgSettings = await loadOrgSettings(org.uid);
  // set administratedOrgObjects.orgList
  setAdministratedOrgList(org, store.state.base.admin.administratableOrgs);
  if (Vue.prototype.$configHelper.isModuleAvailable('accAdmin')) {
    // load administratedOrgObjects.userList
    loadUserList(org.uid);
    // load administratedOrgObjects.groupList
    loadGroupList(org.uid);
    // load administratedOrgObjects.roleList
    loadRoleList(org.uid);
  }
  // load administratedOrgObjects.moduleSettings
  loadModuleOrgSettings(org.uid);
};

export const loadModuleOrgSettings = async (orgUid) => {
  if (store.state.base.admin.administratedOrgObjects.orgSettings.moduleSettings === 'inheritFromParent') {
    orgUid = store.state.base.admin.administratedOrgObjects.orgSettings.parentUid;
  }
  store.state.base.admin.administratedOrgObjects.moduleSettings = {};
  let ioData = {
    targetKey: orgUid,
    contextFilter: 'OrgSettings'
  };
  let processData = await Vue.prototype.$restClient.callProcess('baseAdmin', 'globalPropertiesGetList', ioData);
  if (processData.processState.state === 'success') {
    store.state.base.admin.administratedOrgObjects.moduleSettings = processData.ioData.globalProps;
  } else {
    Vue.prototype.$globals.Info.open(processData.processState.messages);
  }
};

export const inheritOrgSettings = async (parentOrgUid, targetOrgUid, parentOrgShortname, targetOrgShortname) => {
  return new Promise(async (resolve) => {
    // copy global orgSettings from parent
    let ioData = {
      targetKey: parentOrgUid,
      context: 'orgSettings'
    };
    // load parents globalProps
    let resolveValue = null;
    let parentPropsPD = await Vue.prototype.$restClient.callProcess('baseAdmin', 'globalPropertiesGet', ioData);
    if (!isProcessStateOK(parentPropsPD)) {
      Vue.prototype.$globals.Info.open(parentPropsPD.processState.messages);
    } else {
      let globalProps = completeSettings(orgSettingsTemplate, parentPropsPD.ioData.globalProps);
      // modify props
      globalProps.shortname = targetOrgShortname;
      globalProps.parent = parentOrgShortname;
      globalProps.parentUid = parentOrgUid;
      globalProps.ownPortal = false;
      globalProps.ownDefaultContact = false;
      if (globalProps.forum.state === 'own') globalProps.forum.state = 'parent';
      if (globalProps.theme.state === 'setInAccount') globalProps.theme.state = 'inheritFromParent';
      if (globalProps.moduleSettings === 'setInAccount') globalProps.moduleSettings = 'inheritFromParent';

      if (globalProps.subOrgSettings.createSubOrgsRecursive !== true) {
        globalProps.subOrgSettings.createSubOrgs = false;
        globalProps.subOrgSettings.createSubOrgsRecursive = false;
      }
      // save new props
      let ioData = {
        targetKey: targetOrgUid,
        context: 'orgSettings',
        parentKey: parentOrgUid,
        globalProps: globalProps
      };
      let newPropsPD = await Vue.prototype.$restClient.callProcess('baseAdmin', 'globalPropertiesCreate', ioData);
      if (isProcessStateOK(newPropsPD)) {
        resolveValue = newPropsPD.ioData.globalProps;
      } else {
        Vue.prototype.$globals.Info.open(newPropsPD.processState.messages);
      }
      resolve(resolveValue);
    }
  });
};
