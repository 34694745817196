<template>
  <div>
    <v-img
      v-if="$store.state.base.org.logo !== ''"
      :key="$store.state.base.org.logo"
      :src="orgLogo"
      class="orgLogo"
    />
    <span
      v-else
      class="orgName"
    >{{ $store.state.base.org.name }}</span>
  </div>
</template>

<script lang="js">
// import { S3Helper } from '@/base/js/aws/S3Helper';
// import { ImageHelper } from '@/base/js/ImageHelper';

export default {
  name: 'HeaderImage',
  computed: {
    orgLogo () {
      if (this.$store.state.base.org.logo) {
        const assets = this.$configHelper.getModuleConfigParam('acc', 'assets');
        return assets.url + this.$store.state.base.org.logo;
      } else { // if (this.$store.state.base.orgBaseSettings != null && this.$store.state.base.orgBaseSettings.logo != null) {
        let logo = this.$store.state.base.orgBaseSettings.logo || this.$store.state.base.defaultOrg;
        const assets = this.$configHelper.getModuleConfigParam('acc', 'assets');
        return assets.url + assets.pathOrgLogo + logo;
      }
    }
  }
};
</script>

<style scoped>
.orgLogo {
  max-width: 240px;
  max-height: 60px;
}
</style>
