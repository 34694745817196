<template>
  <v-container class="align-center pt-8">
    <v-container class="logo">
      <div class="pl-6 py-8 headline">
        {{ $t('base.components.welcomeTo')}}
      </div>
      <v-img :src="orgLogo" />
    </v-container>
  </v-container>
</template>

<script lang="js">
// import Headline from '@/common/Headline';

export default {
  name: 'Dashboard',
  components: {
    // Headline
  },
  data () {
    return {
      text: ''
    };
  },
  computed: {
    orgLogo () {
      if (this.$store.state.base.darkTheme === true) return this.$configHelper.getResourcesPath() + 'img/logo_ecc_dark.png';
      return this.$configHelper.getResourcesPath() + 'img/logo_ecc.png';
    }
  }
};
</script>

<style scoped>
.logo {
  max-width: 800px;
}
</style>
