/**
 * Logger Instanz (ähnlich https://riptutorial.com/vue-js/example/27220/simple-logger)
 * Name of the log config file is stored in env variable
 */

import Vue from 'vue';
import { isProcessStateOK, defaultProcessState } from '@/base/js/ProcessDataHelper';
import { loadJsonFromUrl } from '@/base/js/JsonLoader';
const appConfig = require('@/.generic/appConfig.json');
/*
log-levels:
  0: no logging
  1: error
  2: warn
  3: log
  4: debug
*/
// _config = {
//   logToConsole: true,
//   logToBackend: false,
//   modules: {
//     api: {
//       toConsole: 4,
//       toBackend: 1
//     },
//     default: {
//       toConsole: 4,
//       toBackend: 1
//     }
//   }
// };

export class Logger {
  _config = {
    logToConsole: true,
    logToBackend: false,
    formatJson: true,
    modules: {
      default: {
        toConsole: 4,
        toBackend: 1
      }
    }
  };

  async init () {
    this._config = await loadJsonFromUrl('/res/' + appConfig.buildId + '/config/logConfig.json');
  }

  // used to store caller infos temporarely
  // _tmpComponentName = null;
  // _tmpFunctionName = null;

  /**
   * @param {*} Vue the Vue plugin
   */
  install (Vue) {
    Vue.prototype.$logger = Logger;
  }

  /**
   * add caller information to ioData.data
   * @param {String} componentName Neme of compenent, javascript file, class, ...
   * @param {String} functionName Name of the caller function (useful because of the many anonymous functions)
   *
   * @returns this pointer
   */
  // withCallerInfo (componentName, functionName = null) {
  //   this._tmpComponentName = componentName;
  //   this._tmpFunctionName = functionName;
  //   return this;
  // }

  async error (context, message, data, ...args) {
    if (this.isLogToConsole(context, 1)) {
      console.error(this.buildLogString(context, message, data, ...args));
    }
    if (this.isLogToBackend(context, 1)) {
      this.writeToBackend('error', this.buildObjectString('userError', context, message, data, ...args));
    }
  }

  async warn (context, message, data, ...args) {
    if (this.isLogToConsole(context, 2)) {
      console.warn(this.buildLogString(context, message, data, ...args));
    }
    if (this.isLogToBackend(context, 2)) {
      this.writeToBackend('warning', this.buildObjectString('warning', context, message, data, ...args));
    }
  }

  async log (context, message, data, ...args) {
    if (this.isLogToConsole(context, 3)) {
      console.log(this.buildLogString(context, message, data, ...args));
    }
    if (this.isLogToBackend(context, 3)) {
      this.writeToBackend('logging', this.buildObjectString('success', context, message, data, ...args));
    }
  }

  async debug (context, message, data, ...args) {
    if (this.isLogToConsole(context, 4)) {
      console.debug(this.buildLogString(context, message, data, ...args));
    }
  }

  buildLogString (context, message, data, ...args) {
    let str = '## ' + context + ' ##  ' + message;
    if (data != null) {
      if (typeof data === 'object') {
        let json = this._config.formatJson === true ? JSON.stringify(data, null, 2) : JSON.stringify(data);
        str += ' \n' + json;
      } else {
        str += ' \n' + data;
      }
      if (args.length > 0) {
        str += ' \n' + args.join(' | ');
      }
    }
    return str;
  }
  buildObjectString (type, context, message, data, ...args) {
    let ioData = {
      appVersion: 'v' + appConfig.appVersion,
      processState: defaultProcessState(type, [message]),
      context: context,
      data: data != null ? data : {}
    };
    if (args.length > 0) {
      ioData.additionalData = args.join(' | ');
    }
    if (type === 'userError') {
      ioData.stackTrace = new Error().stack;
    }
    return ioData;
  }

  isLogToConsole (context, logLevel) {
    try {
      if (this._config.logToConsole) {
        let module = this._config.modules[context];
        if (!module || typeof module === 'undefined') {
          module = this._config.modules.default;
        }
        return module.toConsole >= logLevel;
      }
      return false;
    } catch {
      return false;
    }
  }

  isLogToBackend (context, logLevel) {
    try {
      if (this._config.logToBackend) {
        let module = this._config.modules[context];
        if (!module || typeof module === 'undefined') {
          module = this._config.modules.default;
        }
        return module.toBackend >= logLevel;
      }
      return false;
    } catch {
      return false;
    }
  }

  /**
   * calls the API to write items to dynamo db
   * @param {string} level 'error', 'warning', 'log'
   * @param {} ioData The ioData part of the request
   */
  writeToBackend (level, ioData) {
    // last parameter 'true' suppresses loop if error is  called by following process!
    Vue.prototype.$restClient.callProcess('base', 'log', ioData, false, true)
      .then(async (processData) => {
        if (isProcessStateOK(processData)) {
          // console.debug('writeToBackend(' + level + ')', processData.ioData);
        } else {
          console.error('default', 'default', 'Error writeToBackend(' + level + ')', processData, processData.processState.messages);
        }
      });
  }
}
