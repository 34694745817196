import Vue from 'vue';
import axios from 'axios';
import { store } from '@/plugins/store';

const errorResponse = {
  processState: {
    state: 'networkError',
    messages: [{
      type: 'networkError',
      message: '#error'
    }]
  }
};

const successResponse = {
  processState: {
    state: 'success',
    messages: []
  },
  ioData: {}
};

/**
 * @export
 * @class RestClient
 * @description Encapsulates rest client function, espacially for AWS API-Gateway
 */
export class RestClient {
  /**
   * @description call an AWS API endpoint. endpoint configuration is known in module configuration
   * @param {string} moduleId - unique identifier of module
   * @param {string} processName - the processName of the call
   * @param {ioData} ioData - the process' ioData
   * @param {boolean} suppressLogging - set to true if called by logger to avoid endless loops
   * @returns {Promise<processData>} - request response
   * @memberof RestClient
   */
  async callProcess (moduleId, processName, ioData, showLoader = false, suppressLogging = false) {
    return new Promise(async (resolve) => {
      let response = errorResponse;
      try {
        const conf = Vue.prototype.$configHelper.getRequestParams(moduleId, processName);

        if (typeof conf.tokenPrefix !== 'string') conf.tokenPrefix = '';
        if (typeof conf.stdJson !== 'boolean') conf.stdJson = true;
        if (typeof conf.rawRequest !== 'boolean') conf.rawRequest = false;
        if (typeof conf.auth !== 'boolean') conf.auth = true;
        if (typeof conf.authdest !== 'boolean') conf.authdest = false;

        if (showLoader) Vue.prototype.$globals.Loader.show();
        response = await this.callApi(moduleId, processName, ioData, conf, suppressLogging);
      } catch (e) {
        Vue.prototype.$logger.error('api', e.message);
        response.processState.messages[0].message = e.message;
      } finally {
        if (showLoader) Vue.prototype.$globals.Loader.hide();
        resolve(response);
      }
    });
  }

  async callApi (moduleId, processName, ioData, config, suppressLogging = false) {
    return new Promise(async (resolve) => {
      try {
        let url = Vue.prototype.$configHelper.getAPIUrl(moduleId, config.api) + config.endpoint;
        let body = null;
        let httpHeaders = {};
        if (config.method === 'GET' /* || method === 'DELETE' */) {
          if (config.rawRequest) {
            if (ioData.querystring && typeof ioData.querystring === 'string') {
              url = url + '?' + ioData.querystring;
            }
          } else {
            url = url + '?' + this.buildQueryString(moduleId, ioData, processName);
          }
          if (!suppressLogging) {
            Vue.prototype.$logger.debug('api', 'API Request (' + moduleId + '.' + processName + ') ' + config.method + '\n' + url, ioData);
          }
        } else {
          if (config.rawRequest) {
            body = ioData.body;
          } else {
            body = this.buildProcessData(moduleId, ioData, processName);
          }

          if (!suppressLogging) {
            Vue.prototype.$logger.debug('api', 'API Request (' + moduleId + '.' + processName + ') ' + config.method + '\n' + url, body);
          }
        }

        if (config.rawRequest) {
          if (typeof ioData.httpHeaders !== 'undefined' && ioData.httpHeaders != null) {
            httpHeaders = ioData.httpHeaders;
          }
        } else {
          httpHeaders = await this.buildHttpHeaders(config.tokenPrefix, config.auth, config.authdest);
        }

        if (config.authdest) {
          if (config.method === 'GET') url += '&authdest=' + Vue.prototype.$sessionHelper.getSessionType();
          else url += '?authdest=' + Vue.prototype.$sessionHelper.getSessionType();
        }

        const axiosParams = {
          method: config.method,
          url: url,
          headers: httpHeaders
        };

        if (body) {
          axiosParams.data = body;
        }

        /* let authToken = await SessionHelper.getAuthentificationToken();
if (!authToken) {
  axios.defaults.withCredentials = false;
} */
        axios.defaults.withCredentials = false;

        axios.request(axiosParams)
          .then((response) => {
            if (!suppressLogging) {
              Vue.prototype.$logger.debug('api', 'API Response ' + processName, response.data);
            }
            if (!config.stdJson) {
              let pd = successResponse;
              if (typeof response.data.ioData !== 'undefined') pd.ioData = response.data.ioData;
              else pd.ioData = response.data;
              resolve(pd);
            } else { // for stdJson check for processState
              if (typeof response.data.processState === 'undefined' || response.data.processState === null) {
                throw new Error('base.error.invalidResponseData');
              }
              resolve(response.data);
            }
          })
          .catch((e) => {
            if (!suppressLogging) {
              Vue.prototype.$logger.error('api', 'API Error' + processName + ': ' + e.message);
            }
            const response = errorResponse;
            response.processState.messages[0].message = e.message;
            resolve(response);
          });
      } catch (err) {
        Vue.prototype.$logger.error('api', err.message);
        const response = errorResponse;
        response.processState.messages[0].message = err.message;
        resolve(response);
      }
    });
  }

  async buildHttpHeaders (tokenPrefix, mandatoryAuth, useAuthDest) {
    let headers = {
      'Content-Type': 'application/json'
    };
    let authToken = '';
    authToken = await Vue.prototype.$sessionHelper.getAuthentificationToken();
    if (authToken) {
      headers.authorization = tokenPrefix + authToken;
    } else if (mandatoryAuth) {
      throw new Error('base.error.noAuthentificationToken');
    }
    // if (useAuthDest) {
    //   headers.authdest = Vue.prototype.$sessionHelper.getSessionType();
    // }
    return headers;
  }

  buildProcessData (moduleId, ioData = {}, processName = '') {
    const processData = {
      metaData: {
        user: '' + store.state.base.user.uid,
        userType: Vue.prototype.$sessionHelper.getUserType(),
        org: '' + store.state.base.org.uid,
        orgType: Vue.prototype.$sessionHelper.getOrgType(),
        processName: processName,
        locale: store.state.base.curLocale
      },
      ioData: ioData
    };

    const version = Vue.prototype.$configHelper.getModuleConfigParam(moduleId, 'version');
    if (version) {
      processData.metaData.version = version;
    }

    return processData;
  }

  buildQueryString (moduleId, ioData = {}, processName = '') {
    let version = Vue.prototype.$configHelper.getModuleConfigParam(moduleId, 'version');
    let qs = 'metaData_user=' + store.state.base.user.uid;
    qs += '&metaData_userType=' + Vue.prototype.$sessionHelper.getUserType();

    if (store.state.base.org.uid && store.state.base.org.uid.length > 0) {
      qs += '&metaData_org=' + store.state.base.org.uid;
      qs += '&metaData_orgType=' + Vue.prototype.$sessionHelper.getOrgType();
    }
    qs += '&metaData_locale=' + store.state.base.curLocale;
    qs += '&metaData_processName=' + processName;
    if (version) {
      qs += '&metaData_version=' + version;
    }

    qs += this.buildNestedObjectQueryString(ioData, 'ioData');
    return qs;
  }

  buildNestedObjectQueryString (obj, prefix) {
    let qs = '';
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') {
        qs += this.buildNestedObjectQueryString(obj[key], prefix + '_' + key);
      } else {
        qs += '&' + prefix + '_' + key.toString() + '=' + obj[key];
      }
    });
    return qs;
  };
};
