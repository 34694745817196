import Vue from 'vue';
import VueRouter from 'vue-router';
// import { store } from '@/plugins/store';
import { routes } from '@/.generic/routes';

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: 'history',
  routes: routes
});
/*
router.beforeEach(async (to, from, next) => {
  Vue.prototype.$logger.debug('router', 'Router resolve to ' + router.getMatchedComponents(to)[0].name + ' (' + to.fullPath + ')');
  if (!store.state.base.appPrepared) {
    // set first route to be routed to after app is prepared
    store.state.base.firstRoute = to.fullPath;
  } else {
    if (!to.matched.some(record => record.meta.noAuthRequired)) {
      Vue.prototype.$sessionHelper.getAuthenticatedUser().then((authenticatedUser) => {
        if (authenticatedUser) {
          next();
        } else {
          Vue.prototype.$logger.warn('router', 'Resolve forbidden: ' + to.fullPath + '. ReRoute to SignIn!');
          store.commit('signOut');
          next({ path: Vue.prototype.$sessionHelper.getLoginPath() });
        }
      });
    }
    next();
  }
});
*/
