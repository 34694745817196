import Vue from 'vue';
import 'vuetify/dist/vuetify.min.css';
// import '@/assets/scss/vuetify.css';
import './assets/scss/font.scss';
import './assets/scss/style.scss';
import App from './App.vue';
import VTooltip from 'v-tooltip';
// import VueExcelEditor from 'vue-excel-editor';

import { Logger } from '@/base/js/Logger';
import { RestClient } from '@/base/js/RestClient';
import { SessionHelper } from '@/.generic/SessionHelper';

import { ConfigHelper } from '@/base/js/ConfigHelper';
import { store } from '@/plugins/store';
import { router } from '@/plugins/router';
import { vuetify } from '@/plugins/vuetify';

import tooltip from '@/base/js/tooltip';
import i18nInit from '@/plugins/i18nInit';
import appPrepare from '@/plugins/appPrepare';
import filterInit from '@/plugins/filterInit';
import deviceInit from '@/plugins/deviceInit';

// pwa additions
import './registerServiceWorker';

store.state.base.firstRoute = window.location.pathname;

let logger = new Logger();
Vue.prototype.$logger = logger;
logger.init();

let sessionHelper = new SessionHelper(store.state.base.curLocale);
let configHelper = new ConfigHelper();

Vue.config.productionTip = false;

Vue.prototype.$configHelper = configHelper;
Vue.prototype.$sessionHelper = sessionHelper;
Vue.prototype.$restClient = new RestClient();

sessionHelper.init(configHelper.getConfigParam('defaultOrg'));
i18nInit(store);
filterInit();
appPrepare(store, configHelper, logger, router);

logger.debug('config', 'first route', window.location.pathname);
logger.debug('config', 'Locale: ' + store.state.base.curLocale);
logger.debug('config', 'Preselected Org: ' + store.state.base.defaultOrg);

// define globals
Vue.prototype.$tooltip = tooltip;
Vue.prototype.$filters = Vue.options.filters;
Vue.prototype.$device = deviceInit();
Vue.prototype.$globals = {
  Confirm: {},
  Info: {},
  Loader: null,
  Debugger: null
};

Vue.use(VTooltip);
// Vue.use(VueExcelEditor);

export default new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
