<template>
  <v-layout column>
    <v-list>
      <template v-for="item in elements">
        <!-- menuitems with submenu -->
        <v-list-group
          v-if="item.children != null && checkRights(item)"
          :key="item.name"
          :value="true"
          :class="item.vStyle || ''"
        >
          <template v-slot:activator>
            <v-list-item-icon v-if="item.hStyle !== 'noIcon'">
              <v-icon class="text--primary">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title
              :class="item.hStyle === 'noIcon' ? 'px-4' : ''"
              class="text--primary"
            >
              {{ $t(item.name) }}
            </v-list-item-title>
          </template>
          <v-divider />
          <template v-for="child in item.children">
            <v-list-item
              v-if="checkRights(child)"
              :key="child.name"
              xxxv-for="(child, i) in item.children"
              :to="child.route"
              link
            >
              <v-list-item-icon>
                <v-icon
                  v-if="child.hStyle !== 'noIcon'"
                  v-text="child.icon"
                  :class="item.childIndent === false ? '' : 'childIcon'"
                  class="text--primary"
                />
              </v-list-item-icon>
              <v-list-item-title
                :class="child.hStyle === 'noIcon' ? 'px-4' : ''"
                class="text--primary"
              >
                {{ $t(child.name) }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list-group>
        <!-- standard menuitems -->
        <v-list-item
          v-if="item.children == null && checkRights(item)"
          :key="item.name"
          :to="item.route"
        >
          <v-list-item-icon v-if="item.hStyle !== 'noIcon'">
            <v-icon class="text--primary">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title
            :class="item.hStyle === 'noIcon' ? 'px-4' : ''"
            class="text--primary"
          >
            {{ $t(item.name) }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-layout>
</template>

<script lang="js">
export default {
  name: 'Sidebar',
  props: {
    elements: { type: Array, required: true },
    moduleUid: { type: String, required: true }
  },
  methods: {
    checkRights (element) {
      let orgRight = element.orgSettings == null || element.orgSettings.length === 0;
      let userRight = element.userRights == null || element.userRights.length === 0;
      // console.log('Sidebar.checkRights 1', orgRight, userRight, JSON.stringify(element));
      // checkOrgRights
      if (!orgRight) {
        for (let askedSetting of element.orgSettings) {
          if (this.$store.state[this.moduleUid].orgSettings != null &&
            this.$store.state[this.moduleUid].orgSettings[askedSetting] === true
          ) {
            orgRight = true;
          }
        }
      }
      // console.log('Sidebar.checkRights 2', orgRight, userRight);
      // checkUserRights
      if (orgRight && !userRight) {
        for (let askedRight of element.userRights) {
          if (this.$store.state[this.moduleUid].userRights != null &&
            this.$store.state[this.moduleUid].userRights[askedRight] != null &&
            this.$store.state[this.moduleUid].userRights[askedRight].allowed !== false
          ) {
            userRight = true;
          }
        }
      }
      // console.log('Sidebar.checkRights 3', orgRight, userRight);
      return orgRight && userRight;
    }
  }
};
</script>
