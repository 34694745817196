<template>
  <div>
    <v-divider />
    <v-flex>
      <v-list>
        <v-list-item
          id="sidebarSignOut"
          @click="logout"
        >
          <v-list-item-icon>
            <v-icon class="sidebarItem">mdi-power</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="sidebarItem">{{ $t("base.signOut") }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-flex>
    <v-flex
      pb-4
      ml-7
    >
      <v-row no-gutters>
        <div class="mr-1 pt-1">
          powered by
        </div>
        <v-img
          :src="orgLogo"
          contain
          height="40px"
          max-width="110px"
        />
        <!--<span class="font-italic">eurobase</span>-->
      </v-row>
    </v-flex>
  </div>
</template>

<script lang="js">
export default {
  name: 'SidebarFooter',
  computed: {
    orgLogo () {
      if (this.$store.state.base.darkTheme === true) return this.$configHelper.getResourcesPath() + 'img/logo_eurobase_dark.png';
      return this.$configHelper.getResourcesPath() + 'img/logo_eurobase.png';
    }
  },
  methods: {
    logout () {
      this.$router.push('/goodBye');
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
