import Vue from 'vue';

import { loadTextPackage } from '@/base/js/TextHelper';
import { addMessage, newMessage } from '@/base/js/ProcessDataHelper';
import { store } from '@/plugins/store';
import { loadJsonFromUrl } from '@/base/js/JsonLoader';
import { loadRoleList } from '@/baseAdmin/js/baseAdminHelper';
import { BaseSidebar } from '@/base/struct/BaseSidebar';
import appConfig from '@/.generic/appConfig.json';

export class ConfigHelper {
  _config = appConfig;
  baseModules = [];

  // eslint-disable-next-line space-before-function-paren
  init () {
    return new Promise(async (resolve) => {
      // init default modules
      const promises = [];
      for (let moduleId of Object.keys(appConfig.modules)) {
        promises.push(this.addModule(moduleId, null));
        // this.baseModules.push(moduleId);
      }
      await Promise.all(promises);
      resolve(true);
    });
  }

  getAppConfig (stringify = false) {
    return stringify ? JSON.stringify(this._config) : this._config;
  }

  getStage () {
    return this._config.stage;
  }

  getResourcesPath () {
    return '/res/' + this._config.buildId + '/';
  }

  // getExtOrgUid () {
  //   return this._config.extOrgUid;
  // }

  // getDerivate () {
  //   return this._config.derivate;
  // }

  isModuleActive (moduleId, subModuleId = null) {
    let isActive = store.state.base.curModuleUid === moduleId;
    if (isActive && subModuleId) {
      // ToDo: check if subModule is active
    }
    return isActive;
  }

  getConfigParam (s, mandatory = false, o = this._config) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, ''); // strip a leading dot
    let a = s.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
      let k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        if (mandatory) {
          throw new Error(Vue.prototype.$t('base.error.missingConfigParam') + s);
        }
        return null;
      }
    }
    return o;
  }

  getModuleConfigParam (moduleId, customKey = null, mandatory = false) {
    let key = 'modules.' + moduleId;
    if (customKey) key += '.' + customKey;

    return this.getConfigParam(key, mandatory);
  }

  getRequestParams (moduleId, processName) {
    return this.getModuleConfigParam(moduleId, 'processes.' + processName, true);
  }

  getAPIUrl (moduleId, api) {
    return this.getModuleConfigParam(moduleId, 'apis.' + api, true);
  }

  async loadModule (moduleId, isAdminModule = false) {
    return new Promise(async (resolve) => {
      let type = 'success';
      let text = 'module ' + moduleId + ' initialized';
      try {
        // load configFile
        // Vue.prototype.$logger.debug('config', 'loadModule:', moduleId);
        let config = await loadJsonFromUrl(this.getResourcesPath() + 'config/' + moduleId + 'Config.json');
        // const config = await S3Helper.downloadJson(moduleId + 'Config.json', appConfig.s3.config);
        if (!config) {
          Vue.prototype.$logger.warn('config', 'no configuration for Module: ' + moduleId);
          text = 'module ' + moduleId + ' has no config file';
        } else {
          // if (!store.state.base.curModuleUid ) setActive = true;
          await this.addModule(moduleId, config);
        }
      } catch (err) {
        Vue.prototype.$logger.error('config', 'loadModule: ' + err.message, moduleId);
        type = 'systemError';
        text = err;
      }
      resolve(newMessage(type, text));
    });
  }

  async addModule (moduleId, config = null, force = false) {
    if (!this.isModuleAvailable(module.moduleUid) || force) {
      Vue.prototype.$logger.debug('config', 'addModule', moduleId);
      // add module to configurationList
      let isDefaultModule = false;
      if (config == null) {
        config = appConfig.modules[moduleId];
        isDefaultModule = true;
      } else {
        let mc = JSON.parse(JSON.stringify(config));
        delete mc.store;
        delete mc.routes;
        this._config.modules[moduleId] = mc;
      }
      // register module in store
      if (config.store) {
        store.registerModule(moduleId, config.store);
      }

      if (config.isAdminModule === true) {
        // store.state.base.admin.isAdminMode = true;
        store.state.base.admin.isAdmin = true;
        if (moduleId === 'accAdmin') {
          loadRoleList(store.state.base.org.uid);
        }
        // add module to top admin navigation
        if (config.nav && config.nav.type === 'top' && store.state.base.admin.topAdminNav.indexOf(moduleId) < 0) {
          if (config.nav.prio > store.state.base.admin.topAdminNav.length) {
            store.state.base.admin.topAdminNav.push(config.moduleId);
          } else {
            store.state.base.admin.topAdminNav.splice(config.nav.prio, 0, config.moduleId);
          }
        }
      } else {
        // add module to top navigation
        if (config.nav && config.nav.type === 'top' && store.state.base.topNav.indexOf(moduleId) < 0) {
          if (config.nav.prio > store.state.base.topNav.length) {
            store.state.base.topNav.push(config.moduleId);
          } else {
            store.state.base.topNav.splice(config.nav.prio, 0, config.moduleId);
          }
        }
      }
      // set module as active module
      if (this.getCurrentModuleUid() === moduleId || isDefaultModule) {
        // await this.getExtendedConfig(moduleId);
        for (const textPackage of config.textPackages) {
          await loadTextPackage(textPackage, store.state.base.curLocale);
        }
        this.setCurrentModule(moduleId);
      } else {
        // lazyload extended config from backend
        // this.getExtendedConfig(moduleId);
        // lazyload textpackage
        for (const textPackage of config.textPackages) {
          loadTextPackage(textPackage, store.state.base.curLocale);
        }
      }
    }
  }

  resetModuleList (keepModuleId = null) {
    Vue.prototype.$logger.debug('config', 'ConfigHelper.resetModuleList');

    for (let moduleId of Object.keys(this._config.modules)) {
      if (!this._config.defaultModules.includes(moduleId)) {
        delete this._config.modules[moduleId];
        store.unregisterModule(moduleId);
        if (this.baseModules.includes(moduleId)) this.baseModules.splice(this.baseModules.indexOf(moduleId), 1);
        if (store.state.base.admin.topAdminNav.includes(moduleId)) store.state.base.admin.topAdminNav.splice(store.state.base.admin.topAdminNav.indexOf(moduleId), 1);
        if (store.state.base.topNav.includes(moduleId)) store.state.base.topNav.splice(store.state.base.topNav.indexOf(moduleId), 1);
      }
    }
    this.baseModules = [];
    store.state.base.admin.isAdminMode = false;
    store.state.base.sidebar = new BaseSidebar();
    this.setCurrentModule('base');
  }

  async loadModuleList (processData) {
    return new Promise(async (resolve) => {
      const promises = [];
      for (let module of processData.ioData.moduleList) {
        if (!this.isModuleAvailable(module.moduleUid)) {
          promises.push(this.loadModule(module.moduleUid));
        }
        this.baseModules.push(module.moduleUid);
        if (Array.isArray(module.adminRoleUids) && module.adminRoleUids.length > 0) {
          if (!this.isModuleAvailable(module.moduleUid + 'Admin')) {
            promises.push(this.loadModule(module.moduleUid + 'Admin', true));
          }
        }
        // for (let adminModuleUid of module.adminRoleUids) {
        //   promises.push(this.loadModule(adminModuleUid));
        // }
      }
      const moduleMessages = await Promise.all(promises);
      for (const moduleMessage of moduleMessages) {
        processData.processState = addMessage(processData.processState, moduleMessage.type, moduleMessage.message);
      }

      // update currentModule from path
      let path = window.location.pathname;
      path = path.substring(1, path.indexOf('/', 1));
      if (path.length > 1) this.setCurrentModule(path);

      resolve(processData);
    });
  }

  setCurrentModule (moduleId) {
    Vue.prototype.$logger.warn('config', 'ConfigHelper.setCurrentModule: ' + moduleId);

    // if (moduleId !== 'base') {
    //   if (this.isAdminModule()) {
    //     store.state.base.admin.isAdminMode = true;
    //   } else {
    //     store.state.base.admin.isAdminMode = false;
    //   }
    // }
    store.state.base.admin.isAdminMode = true;
    store.state.base.curModuleUid = moduleId;
    if (this.isModuleAvailable(moduleId)) {
      // Vue.prototype.$logger.debug('config', 'Set sider for ' + moduleId);
      // store.state.base.sidebar = this.getCurrentModuleSidebar();
    }
  }

  getCurrentModuleUid () {
    return store.state.base.curModuleUid;
  }

  getCurrentModuleSidebar () {
    Vue.prototype.$logger.warn('config', 'ConfigHelper.getCurrentModuleSidebar: ' + store.state.base.curModuleUid);
    let sidebar = this.getModuleConfigParam(store.state.base.curModuleUid, 'sidebar');
    if (sidebar == null) sidebar = [];
    return sidebar;
  }

  removeModule (moduleId) {
    this._config.modules[moduleId] = null;
    for (let i = 0; i < this.baseModules.length; i++) {
      if (this.baseModules[i] === moduleId) {
        delete this.baseModules[i];
      }
    }
  }

  isModuleAvailable (moduleId) {
    const moduleConfiguration = this._config.modules[moduleId];
    if (typeof moduleConfiguration === 'undefined' || moduleConfiguration === null) {
      return false;
    }
    return true;
  }

  isAdminModule (moduleId = store.state.base.curModuleUid) {
    const moduleConfiguration = this._config.modules[moduleId];
    if (typeof moduleConfiguration !== 'undefined' && moduleConfiguration !== null) {
      if (typeof moduleConfiguration.adminModule !== 'undefined' && moduleConfiguration.adminModule !== null) {
        return moduleConfiguration.adminModule;
      }
    }
    return false;
  }

  // getRequestParams (moduleId, processName) {
  //   return this.getModuleConfigParam(moduleId).processes[processName];
  // }

  getDefaultRoute (moduleId) {
    return this.getModuleConfigParam(moduleId, 'defaultRoute');
  }

  getModuleList (param = null, value = true) {
    const list = [];
    for (let moduleId of Object.keys(this._config.modules)) {
      if (param == null) {
        list.push(moduleId);
      } else {
        if (this.getModuleConfigParam(moduleId, param) === value) {
          list.push(moduleId);
        }
      }
    }
    return list;
  }

  getBaseModules () {
    return this.baseModules;
  }
};
