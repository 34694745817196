<template>
  <v-toolbar-items>
    <v-menu
      bottom
      left
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          icon
          height="28px"
          width="28px"
        >
          <v-img v-bind:src="
              require('@/assets/flags/' + currentLocale + '.png')
            " />
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="locale in availableLocales"
          :key="locale.short"
          @click="changeLocale(locale.short)"
        >
          <v-img
            v-bind:src="
              require('@/assets/flags/' + locale.short + '.png')
            "
            height="32px"
            width="32px"
          />
        </v-list-item>
      </v-list>
    </v-menu>
  </v-toolbar-items>
</template>

<script lang="js">
import { setCookie } from 'tiny-cookie';
import { loadTextPackage } from '@/base/js/TextHelper';
const appConfig = require('@/.generic/appConfig.json');

export default {
  name: 'LocaleSelector',
  computed: {
    currentLocale () {
      return this.$store.state.base.curLocale;
    },

    availableLocales () {
      return appConfig.locales.list;
    }
  },
  methods: {
    changeLocale (newLocale) {
      this.$i18n.set(newLocale);
      this.$store.commit('setLanguage', newLocale);
      this.$vuetify.lang.current = newLocale;
      this.$Amplify.I18n.setLanguage(newLocale);
      setCookie('locale', newLocale, { expires: '1Y' });
      this.$logger.debug('base', 'i18next current Locales: ' + this.$store.state.base.curLocale);
      // load BaseTextPackage and TextPackage for current Module
      loadTextPackage('base', newLocale);
      let textPackages = this.$configHelper.getModuleConfigParam(this.$configHelper.getCurrentModuleUid(), 'textPackages');
      if (Array.isArray(textPackages)) {
        for (const textPackage of textPackages) {
          loadTextPackage(textPackage, newLocale);
        }
      }
    }
  }
};
</script>

<style scoped>
</style>
