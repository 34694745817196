export const amplifyDict = {
  de: {
    // Login-Dialog
    'Sign in to your account': 'Melden Sie sich mit Ihrem EuroConsultant.cloud-Account an',
    'Sign In': 'Anmelden',
    'Username *': 'Benutzername',
    'Enter your username': 'Benutzername oder Email-Adresse',
    'Password *': 'Passwort',
    'Enter your password': 'Geben sie ihr Passwort ein',
    'No account?': 'Noch kein Konto? ',
    'Create account': 'Konto anlegen',
    'Forgot your password?': 'Passwort vergessen? ',
    'Reset password': 'Passwort zurücksetzen',
    // Fehler-Texte
    'Password cannot be empty': 'Passwort fehlt',
    'Username cannot be empty': 'Geben sie Ihre Benutzernamen an',
    'User does not exist.': 'Unbekannter Benutzername',
    'Incorrect username or password.': 'Logindaten fehlerhaft.',
    'Username/client uid combination not found.': 'Unbekannter Benutzername',
    'Code cannot be empty': 'Code fehlt',
    'Contact administrator to reset password.': 'Kontaktieren sie Ihren Administrator um das Passwort zurückzusetzen.',
    // Reset Password-Dialog
    'Reset your password': 'Passwort zurücksetzen',
    'Back to Sign In': 'Zurück zur Anmeldung',
    'Send Code': 'Code senden',
    'New Password': 'Neues Passwort',
    'Resend Code': 'Code erneut senden',
    'Submit': 'Absenden',
    // Passwort ändern forciert
    'Enter new password': 'Sie müssen Ihr Passwort ändern.',
    // Email-Verification/Recovery
    'Account recovery requires verified contact information': 'Zur Sicherheit muss ihre Email-Adresse verifiziert werden',
    'Skip': 'jetzt nicht',
    'Verify': 'Verifizierungsmail senden',
    'Enter code': 'Code eingeben'
  },
  en: {
    // Login-Maske
    'Sign in to your account': 'Sign in to your DigitalTrainer365 account'
  }
};
