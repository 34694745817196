<template>
  <v-overlay
    :value="active > 0"
    absolute
    opacity="0.4"
    color="grey"
  >
    <v-flex text-center>
      <v-progress-circular
        @click="active = 0"
        indeterminate
        color="accent"
        size="128"
        width="12"
      ></v-progress-circular>
    </v-flex>
  </v-overlay>
</template>

<script lang="js">
export default {
  name: 'Loader',
  data () {
    return {
      active: 0
    };
  },
  methods: {
    show () {
      this.active++;
    },
    hide () {
      this.active--;
    }
  }
};
</script>

<style scoped>
</style>
