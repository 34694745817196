<template>
  <v-toolbar-items>
    <!--<v-icon color="secondary">
      mdi-bell
    </v-icon>-->
    <v-menu
      offset-y
      tile
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          text
          id="userMenu"
          class="primary primaryContrast--text"
        >
          {{ username }}
          <v-avatar
            size="36px"
            item
            class="ml-3"
          >
            <v-img
              v-if="avatar"
              :src="avatar"
            />
            <v-icon
              v-else
              large
            >
              mdi-account-circle-outline
            </v-icon>
          </v-avatar>
        </v-btn>
      </template>
      <v-list>
        <v-list-item to="/acc/profile">
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("base.header.profile") }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/chooseOrg">
          <v-list-item-icon>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("base.header.changeOrg") }}</v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item
          v-if="$configHelper.getStage() === 'local' || $configHelper.getStage() === 'dev'"
          @click="$globals.Debugger.show()"
        >
          <v-list-item-icon>
            <v-icon class="error--text">mdi-file-code</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="error--text">Debugger</v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item
          id="userMenuSignOut"
          @click="logout"
        >
          <v-list-item-icon>
            <v-icon>mdi-power</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("base.signOut") }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-toolbar-items>
</template>

<script lang="js">
export default {
  name: 'UserMenu',
  computed: {
    username () {
      return (
        this.$store.state.base.user.firstname + ' ' + this.$store.state.base.user.surname
      );
    },
    avatar () {
      if (this.$store.state.base.user.avatarBin) {
        return this.$store.state.base.user.avatarBin;
      } else if (typeof this.$store.state.base.user.avatar === 'string' &&
        this.$store.state.base.user.avatar.length > 0 && this.$store.state.base.user.avatar !== '-') {
        return this.$store.state.base.user.avatar;
      } else {
        // let assets = this.$configHelper.getModuleConfigParam('acc', 'assets');
        // let src = assets.url + assets.pathUserAvatar + '_default_avatar';
        // if (this.$store.state.base.darkTheme === true) src += '_dark';
        // return src;
        return null;
      }
    }
  },
  methods: {
    logout () {
      this.$router.push('/goodBye');
    }
  }
};
</script>

<style scoped>
</style>
