import Vue from 'vue';
import { getCookie, setCookie, removeCookie } from 'tiny-cookie';

import Amplify, { I18n } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import '@aws-amplify/ui-vue';
import { amplifyDict } from '@/acc/js/amplifyDictionary';
import { store } from '@/plugins/store';

import { isProcessStateOK, addMessage } from '@/base/js/ProcessDataHelper';
import { initAdministratedOrg } from '@/baseAdmin/js/baseAdminHelper';
import { accHelper } from '@/acc/js/accHelper';
const appConfig = require('@/.generic/appConfig.json');

/**
 *
 *
 * @export
 * @class SessionHelper
 * @description Encapsulation of functions concerning session handling
 */
export class SessionHelper {
  userIdentifier = null;
  _userUidType = 'ACC_USER';
  _orgUidType = 'ACC_ORG';

  init (defaultOrg) {
    let locale = this.handleUrlParamsAndCookies(defaultOrg);
    Amplify.configure({
      Auth: appConfig.cognito
    });

    Amplify.Auth.configure({
      Auth: appConfig.cognito
      // storage: cognitoStore
    });

    I18n.putVocabularies(amplifyDict);
    I18n.setLanguage(locale);
  }

  getSessionType () {
    return 'acc';
  }

  setDefaultUidTypes () {
    store.state.base.user.uidType = this._userUidType;
    store.state.base.org.uidType = this._orgUidType;
  }

  getUserType (preUnderScore = false) {
    if (preUnderScore) return '_' + this._userUidType;
    return this._userUidType;
  }

  getOrgType (preUnderScore = false) {
    if (preUnderScore) return '_' + this._orgUidType;
    return this._orgUidType;
  }

  getLoginPath () {
    return '/acc/login';
  }

  /**
   *
   *
   * @description signs in a user to cognito
   * @param {string} username
   * @param {string} pwd
   * @returns {Promise<CognitoUser | Error>} - resolves with signedIn CognitoUser
   * @memberof SessionHelper
   */
  async signInManually (username, pwd) {
    return new Promise((resolve, reject) => {
      Auth.signIn(username, pwd).then((user) => {
        Vue.prototype.$logger.debug('auth', user.challengeName);
        if (user.challengeName === 'SMS_MFA' ||
          user.challengeName === 'SOFTWARE_TOKEN_MFA' ||
          user.challengeName === 'NEW_PASSWORD_REQUIRED' ||
          user.challengeName === 'MFA_SETUP') {
          reject(new Error('unsupported challenge'));
        } else {
          // this.initUser(store.state.base.user.uid);
          // this.initOrganisation(store.state.base.org.uid);
          resolve(user);
        }
      }).catch((err) => {
        reject(err.code);
      });
    });
  }

  async getAuthenticatedUser () {
    return new Promise((resolve) => {
      Auth.currentAuthenticatedUser()
        .then((cognitoUser) => {
          this.userIdentifier = cognitoUser.signInUserSession.getIdToken().payload['custom:uid'];
          resolve(cognitoUser);
        })
        .catch((err) => {
          resolve(null);
          Vue.prototype.$logger.debug('auth', err);
        });
    });
  }

  async getAccToken () {
    const user = await this.getAuthenticatedUser();
    if (user) return user.signInUserSession.getAccessToken();
    return null;
  }

  async getAccJwtToken () {
    const user = await this.getAuthenticatedUser();
    if (user) return user.signInUserSession.getAccessToken().getJwtToken();
    return null;
  }

  async getAuthentificationToken () {
    return this.getAccJwtToken();
  }

  /**
   *
   *
   * @async
   * @method signOut
   * @memberof SessionHelper
   */
  async signOut () {
    removeCookie('locale');
    removeCookie('org');
    removeCookie('auth');
    store.commit('signOut');
    localStorage.clear();
    await Auth.signOut({ global: true });
  }

  /**
   *
   * @param {boolean} [isRemainSignedIn=false]
   * @returns {Promise}
   * @memberof SessionHelper
   */
  async initUser (isRemainSignedIn = false) {
    let userIdentifier = this.userIdentifier;
    Vue.prototype.$logger.debug('auth', 'initUser: ' + userIdentifier + '  org: ' + store.state.base.defaultOrg);
    store.state.base.user.uid = userIdentifier;

    let ioData = {};
    if (store.state.base.preSelectedOrg != null) {
      ioData.preSelectedOrg = store.state.base.defaultOrg;
    }

    if (isRemainSignedIn) {
      Auth.currentAuthenticatedUser().then((cognitoUser) => {
        const { refreshToken } = cognitoUser.getSignInUserSession();
        const auth = {
          u: cognitoUser.getUsername(),
          t: refreshToken
        };
        setCookie('auth', JSON.stringify(auth), { expires: '28D' });
      });
    }

    return new Promise((resolve) => {
      Vue.prototype.$restClient.callProcess(
        'acc',
        'userInit',
        ioData
      ).then(async (processData) => {
        if (isProcessStateOK(processData)) {
          // updateUserData
          let assets = Vue.prototype.$configHelper.getModuleConfigParam('acc', 'assets');
          store.state.base.user = accHelper.copyUser(processData.ioData.targetUser, assets.url);
          store.state.base.signedInState = 2; // userReady
          // updateOrganisationsList
          store.state.base.orgList = processData.ioData.orgList.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase());
          // init org if delivered => has to be done through checkAuth-function
          // if (processData.ioData.targetOrg.uid) {
          //   processData = await this.setOrganisation(processData);
          // }
        }
        resolve(processData);
      });
    });
  }

  async initOrganisationByName (shortname) {
    Vue.prototype.$logger.debug('auth', 'initOrganisationByName: ' + shortname);

    return new Promise((resolve) => {
      Vue.prototype.$restClient.callProcess(
        'acc',
        'organisationInit',
        { preSelectedOrg: shortname }
      ).then(async (processData) => {
        if (isProcessStateOK(processData)) {
          // update org
          processData = await this.setOrganisation(processData);
        }
        resolve(processData);
      });
    });
  }

  async initOrganisation (orgUid) {
    Vue.prototype.$logger.debug('auth', 'initOrganisation: ' + orgUid);
    store.state.base.org.uid = orgUid;

    return new Promise((resolve) => {
      Vue.prototype.$restClient.callProcess(
        'acc',
        'organisationInit',
        {}
      ).then(async (processData) => {
        if (isProcessStateOK(processData)) {
          // update org
          processData = await this.setOrganisation(processData);
        }
        resolve(processData);
      });
    });
  };

  async setOrganisation (processData) {
    return new Promise(async (resolve) => {
      try {
        // update org
        store.state.base.org = accHelper.copyOrganisation(processData.ioData.targetOrg);
        store.state.base.signedInState = 3; // orgReady
        // update Cookie
        setCookie('org', store.state.base.org.shortname, { expires: '28D' });
        // init configuration for modules
        Vue.prototype.$configHelper.resetModuleList();
        processData = await Vue.prototype.$configHelper.loadModuleList(processData);
        // if possible, set AdminMode on, because app is now (Feb. 2021) designed as admin-tool
        if (store.state.base.admin.isAdmin) {
          store.state.base.admin.isAdminMode = true;
          initAdministratedOrg(store.state.base.org);
        }

        resolve(processData);
      } catch (e) {
        Vue.prototype.$logger.error('auth', 'Set Organisation: ' + e.message);
        resolve(addMessage(processData, 'systemError', e.message));
      }
    });
  }

  encodeCookieData (auth) {
    let cd = {
      auth: auth,
      org: store.state.base.preselectedOrg,
      baseOrg: store.state.base.defaultOrg
    };
    return window.btoa(JSON.stringify(cd));
  }

  decodeCookieData (cd) {
    let dec = JSON.parse(window.atob(cd));
    store.state.base.preselectedOrg = dec.selOrg;
    store.state.base.defaultOrg = dec.baseOrg;
    this.userIdentifier = dec.auth;
    this.getAuthenticatedUser();
  }

  // private
  handleUrlParamsAndCookies (defaultOrg) {
    // set the locale
    let curLocale = getCookie('locale');
    if (!curLocale) {
      curLocale = 'de'; // navigator.language.split('-')[0];
    }
    store.state.base.curLocale = curLocale;

    // Read out org-parameter for custom design
    // priority:
    // - Parameter
    // - Subdomain
    // - Cookie
    // - Default
    const urlParams = new URLSearchParams(window.location.search);

    let preSelectedOrg = urlParams.get('org');
    // const subDomain = window.location.hostname;
    // logger.debug('config', 'subdomain: ' + subDomain);
    if (preSelectedOrg == null) {
      preSelectedOrg = getCookie('org');
    }
    if (preSelectedOrg == null) {
      store.state.base.defaultOrg = defaultOrg;
    } else {
      store.state.base.preSelectedOrg = preSelectedOrg;
      store.state.base.defaultOrg = preSelectedOrg;
    }

    return curLocale;
  }
};
